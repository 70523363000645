import React, { FC, useMemo } from "react";

// import subject from "@csgp-mfe-utils/event-observer";
import { Anchor, Box, Divider, Icon, List, ListItem, ListItemText } from "@costar/theme-ui";

// import { ProductGroup } from "../../../common";
import { HOME_URL, NavConfig, ProductId, ROOT_URL } from "../../../common";
import { LinkItem } from "../masthead/types";

// import { mfeMenuSubject } from "../../subjects/main-menu";
// import { shouldUpsellList } from "../../utils/upsell-list";

const commonListStyles = {
    variant: "ghostMod",
    px: "lg",
    fontSize: "md",
    fontWeight: "400",
    fontFamily: "Helvetica",
};

export type SideBarMenuProps = {
    onClose: (event: React.KeyboardEvent | React.MouseEvent) => void;
    onTabClick: (tabId: ProductId) => void;
    rootUrl?: string;
    loginLabel?: string;
    loginUrl?: string;
    topLinks?: LinkItem[];
    /**
     * @todo when the auth hamburger menu is created and this is moved to the unauth
     * folder, remove this and, instead, consume the unauth nav config context.
     */
    navConfig: NavConfig;
};

const SideBarMenu: FC<SideBarMenuProps> = props => {
    const {
        onClose,
        onTabClick,
        loginLabel = "Login",
        rootUrl = ROOT_URL,
        loginUrl = HOME_URL,
        topLinks = [],
        navConfig = [],
    } = props ?? {};

    // const handleMenuClick = (item: ProductGroup, menuId: number) => () => {
    //     if (item.href && !shouldUpsellList.includes(item.id)) {
    //         window.location.assign(item.href);
    //     } else {
    //         subject(mfeMenuSubject).notify(menuId);
    //     }
    // };

    const tabList = useMemo(
        () =>
            navConfig.map(tab => (
                <ListItem button key={tab.id} onClick={onTabClick.bind(undefined, tab.id)} {...commonListStyles}>
                    <ListItemText as="a" primary={tab.text} href={tab.href || "#"} color="text" disabled />
                </ListItem>
            )),
        [onTabClick, navConfig]
    );

    return (
        <Box
            role="presentation"
            data-testid="hamburger-menu"
            as="menu"
            minWidth="300px"
            fontFamily="costar"
            onClick={onClose}
            onKeyDown={onClose}
        >
            <Anchor px="lg" py="md" href={rootUrl}>
                <Icon icon="costarLogo" height={40} width={145} />
            </Anchor>
            <Divider />
            <List>
                {topLinks.map(link => (
                    <ListItem button key={`${link.label || ""} + ${link.url || ""}`} {...commonListStyles}>
                        <ListItemText as="a" primary={link.label} href={link.url} color="text" />
                    </ListItem>
                ))}
                <ListItem button {...commonListStyles}>
                    <ListItemText
                        as="a"
                        flexDirection="row"
                        primary={
                            <>
                                <Icon icon="mui-person" />
                                &nbsp;{loginLabel}
                            </>
                        }
                        href={loginUrl}
                        color="text"
                    />
                </ListItem>
            </List>
            <Divider />
            <List>{tabList}</List>
        </Box>
    );
};

export default SideBarMenu;
