import type { Draft } from "immer";
import produce from "immer";
import { Reducer } from "react";
import { ProductId } from "src/common/nav-config";

import { NavState } from "../state";

import { ACTIONS, NavAction } from "./state.actions";

/**
 * Main reducer managing application state. This is plugged into a `useReducer`
 * hook. Note that {@link produce immer} is used, allowing for imperative-style
 * code that has no side effects.
 *
 * @param state A {@link Draft} of the previous state. This can be "mutated" safely.
 * @param action the {@link NavAction action} that was dispatched.
 *
 * @returns The new state.
 *
 * @see [Immer Docs](https://immerjs.github.io/immer/)
 *
 * The links below are for those unfamiliar with the reducer pattern.
 *
 * @see [Redux - Concepts and Data Flow](https://redux.js.org/tutorials/fundamentals/part-2-concepts-data-flow)
 * @see [Redux - State, Actions, and Reducers](https://redux.js.org/tutorials/fundamentals/part-3-state-actions-reducers)
 */
export const stateReducer: Reducer<NavState, NavAction> = produce((state, action) => {
    switch (action.type) {
        // show/hide a subnav button
        case ACTIONS.ShowButton: {
            const { button, value } = action;
            const { subnav } = state;

            // Create the button state object if it doesn't exist, then set
            // it's visibility
            if (button in subnav.actionButtonState) {
                subnav.actionButtonState[button]!.show = value;
            } else {
                subnav.actionButtonState[button] = {
                    show: value,
                };
            }

            break;
        }
        // toggle a subnav button (e.g. favorites)
        case ACTIONS.ToggleButton: {
            const { button, value } = action;
            const { subnav } = state;

            if (button in subnav.actionButtonState) {
                subnav.actionButtonState[button]!.toggled = value;
            } else {
                subnav.actionButtonState[button] = {
                    toggled: value,
                    show: true,
                };
            }
            break;
        }

        /**
         * Sets next & previous button visibility.
         * Updates counters from subject to reducer
         */
        case ACTIONS.SetPagerCounts: {
            const { counterKey, value } = action;
            const { subnav } = state;
            if (isNaN(value)) {
                break;
            } else {
                //check for nulls
                subnav.actionButtonState.previous ??= {};
                subnav.actionButtonState.next ??= {};
                subnav.activeRecordIndex ??= -1;
                subnav.resultSetTotalCount ??= 0;

                subnav[counterKey] = value;

                const showButtons = subnav.activeRecordIndex >= 0 && subnav.resultSetTotalCount > 1;
                subnav.actionButtonState.next.show = showButtons;
                subnav.actionButtonState.previous.show = showButtons;
            }
            break;
        }

        // Updates counters from event (onClick prev or next) to reducer
        case ACTIONS.UpdateSubmenuRecordCounter: {
            const { value } = action;
            const { subnav } = state;
            subnav.activeRecordIndex ??= -1;
            subnav.resultSetTotalCount ??= 0;

            if (!value && subnav.activeRecordIndex === 0) {
                subnav.activeRecordIndex = subnav.resultSetTotalCount;
            } else if (value && subnav.activeRecordIndex === subnav.resultSetTotalCount - 1) {
                subnav.activeRecordIndex = -1;
            }
            value ? (subnav.activeRecordIndex += 1) : (subnav.activeRecordIndex -= 1);
            break;
        }

        case ACTIONS.NavChange: {
            const { nav } = state;
            const { tabSelected } = action;
            nav.tabSelection = tabSelected;
            break;
        }

        case ACTIONS.SubNavChange: {
            const { subnav } = state;
            const { subTabSelected } = action;
            subnav.subNavTabSelection = subTabSelected;
            break;
        }

        case ACTIONS.ShowSubnav: {
            const { value } = action;
            const { subnav } = state;
            subnav.visible = value;
            break;
        }

        case ACTIONS.ShowOmnisearch: {
            const { value } = action;
            const { masthead } = state;
            masthead.showOmniSearch = value;
            break;
        }

        case ACTIONS.HideRequestTraining: {
            const { value } = action;
            const { masthead } = state;
            masthead.hideRequestTraining = value;
            break;
        }

        case ACTIONS.ShowMastheadAlert: {
            const { value } = action;
            const { masthead } = state;
            masthead.alerts.show = value;
            break;
        }

        case ACTIONS.ShowMastheadAlertCount: {
            const { value } = action;
            const { masthead } = state;
            masthead.alerts.alertCount = value;
            break;
        }

        case ACTIONS.SubnavLabel: {
            const { value } = action;
            const { subnav } = state;
            subnav.subnavLabel = value;
            break;
        }
        case ACTIONS.FavoritesCount: {
            const { value } = action;
            const { subnav } = state;
            subnav.favoritesCount = value;
            break;
        }
        case ACTIONS.ResultSetTotalCountTooltipText: {
            const { value } = action;
            const { subnav } = state;
            subnav.resultSetTotalCountTooltipText = value;
            break;
        }
        case ACTIONS.ActiveProductKey: {
            const { nav, subnav } = state;
            const { value } = action;

            if (value === "LEASING" || value === "FOR_LEASE") {
                nav.tabSelection = "LEASING"; // We need to set both the nav and subnav underline if one of the leasing products are selected.
                subnav.subNavTabSelection = "FOR_LEASE";
            }
            break;
        }

        case ACTIONS.ShowUpsellModal: {
            const { upsell } = state;
            const { upsellUserSubscriber, upsellProductKey } = action;
            upsell.upsellUserSubscriber = upsellUserSubscriber;
            upsell.upsellProductKey = upsellProductKey;
            break;
        }

        case ACTIONS.BackButtonOverrideLabel: {
            const { value } = action;
            const { subnav } = state;
            subnav.backButtonOverrideLabel = value;
            break;
        }

        case ACTIONS.DetailedNavigationEvent:
            state.nav.emitDetailedClickEvent = action.value;
            break;
    }

    return state;
});
