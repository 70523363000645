import { setCultureCookie } from "./util";

import { CountryCode } from "$graphql/core";
import { Nullish } from "$types";

/**
 * Executes a search on classic professionals, also called CPD (CoStar
 * Professionals Directory).
 *
 * Navigation is deferred to a later event-loop cycle to allow for post-click,
 * but pre-navigation cleanup.
 */
export function searchClassicProfessionals(
    contactName: Nullish<string>,
    companyName: Nullish<string>,
    country: CountryCode,
    cpdUri: string
): Promise<void> {
    return Promise.resolve().then(() => {
        setCultureCookie(country);
        const form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", cpdUri);

        const contact = document.createElement("input");
        contact.setAttribute("name", "txtContact");
        contact.setAttribute("value", contactName ?? "");
        form.appendChild(contact);

        const company = document.createElement("input");
        company.setAttribute("name", "txtCompany");
        company.setAttribute("value", companyName ?? "");
        form.appendChild(company);

        const market = document.createElement("input");
        market.setAttribute("name", "htmlSelectMarket");
        market.setAttribute("value", "0");
        form.appendChild(market);

        document.body.appendChild(form);
        form.submit();
    });
}
