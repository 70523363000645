import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import type { DocumentNode, QueryHookOptions } from "@apollo/client";

import { Box, BoxProps, Flex } from "@costar/theme-ui";
import {
    AutocompleteChangeReason,
    AutocompleteInputChangeReason,
    createFilterOptions,
} from "@costar/theme-ui-autocomplete";
import {
    AsyncAutocomplete,
    AsyncAutocompleteProps,
    AutocompleteRenderGroupParams,
    renderHighlightMatchSearchItem,
} from "@costar/theme-ui-autocomplete";
import { IconSearch } from "@costar/theme-ui-icons/csg/IconSearch";
import { Select } from "@costar/theme-ui-select";

import { OMNI_SEARCH_QUERY, RECENTLY_VIEWED_QUERY } from "../../../graphql/core/queries/omni-search";
import { AppFeatures } from "../../../server/render/types";
import { FeatureToggleHeaders } from "../../../types";
import { useCulture, useIsomorphicQuery, useStrictI18N } from "../../hooks";
import { useAuthNavConfigContext } from "../nav-config";

import { useOptionsText } from "./hooks";
import { RecentSearch } from "./recent-searches";
import { RecentlyViewed } from "./recently-viewed";
import { searchClassicProfessionals, searchClassicTenant } from "./search";
import { CountryCode, SearchItemOption, SearchType, SearchTypeInfo } from "./types";
import { convertRecentlyViewedArrayToObject, hasSearchKeys, hrefRedirect, isSearchHrefRedirect } from "./utils";

import { useLogger } from "$client/shared/logger";
import { MainProductIds, SectionProductIds, setUpsellModal, toError, useConfigContext, useUpsellState } from "$common";
import { client } from "$graphql/client/client-side-provider";
import type { RecentlyViewed as RecentlyViewedType, SearchItem } from "$graphql/core";
import { SearchType as SearchTypeGQL } from "$graphql/core";

if (process.env.NODE_ENV !== "production") {
    Select.displayName ||= "Select";
    (AsyncAutocomplete as FC).displayName ||= "AsyncAutocomplete";
}
// export type SearchResult = SearchResultBase & {
//     group?: JSX.Element;
//     id?: string;
//     term?: string;
// };

export type SearchTypeAheadProps = {
    showDetailOmniSearch?: boolean;
    openSearch?: boolean;
    detailSearchClick?: () => void;
};

const recentSearchesKey = "REACT_UUI.RECENT_SEARCHES";

// FIXME
const OptionsData: SearchItemOption[] = [{ label: "", id: "" } as any];

const EMPTY_OPTIONS: SearchItemOption[] = [];

let RecentSearches: SearchItemOption[];
const searchIcon = <IconSearch color="neutral.dark" />;

const popoverProps = {
    disableScrollLock: true,
};
const paneProps = {
    minWidth: "140px",
    fontSize: "sm",
};
const sx = {
    left: "1.3px",
    "&:hover": {
        zIndex: 5,
    },
};
const componentsProps = {
    popover: {
        disableScrollLock: true,
    },
    pane: {
        fontSize: "sm",
    },
    listbox: {
        maxHeight: "500px",
        maxWidth: "600px",
    },
};
const countrySelectorPaneProps = {
    fontSize: "sm",
};
const countrySelectorPopoverProps = {
    disableScrollLock: true,
    paneProps: {
        fontSize: "sm",
    },
};

const getOwnersEnabled = (appFeatures: AppFeatures): boolean => {
    if (appFeatures?.[FeatureToggleHeaders.OWNERS] === "true") return true;
    if (appFeatures?.[FeatureToggleHeaders.OWNERS] === "false") return false;
    if (appFeatures?.["Owners"] === "true") return true;
    return false;
};

const SearchTypeAhead: FC<SearchTypeAheadProps> = ({ showDetailOmniSearch, detailSearchClick, openSearch }) => {
    const logger = useLogger();
    const resources = useStrictI18N();

    const availableCountries = useMemo(() => {
        return {
            [CountryCode[CountryCode.US]]: {
                label: resources?.text?.threeWordCulture?.en_US(),
                sortOrder: CountryCode.US,
            },
            [CountryCode[CountryCode.CA]]: {
                label: resources?.text?.threeWordCulture?.en_CA(),
                sortOrder: CountryCode.CA,
            },
            [CountryCode[CountryCode.GB]]: {
                label: resources?.text?.threeWordCulture?.en_GB(),
                sortOrder: CountryCode.GB,
            },
        };
    }, [resources]);

    const {
        appFeatures = {},
        options: { navLinks },
    } = useConfigContext();
    const { dispatch } = useUpsellState();
    const { navConfig } = useAuthNavConfigContext();
    const [searchType, setSearchType] = useState(SearchTypeGQL["AllProperties"]);
    const [reset, setReset] = useState(true);
    const [showCountrySelector, setShowCountrySelector] = useState<boolean>(false);
    const [countryCodeSelected, setCountryCodeSelected] = useState(CountryCode[CountryCode.US]);
    const [recentlyViewed, setRecentlyViewed] = useState({});
    const [skipRecentlyViewed, setSkipRecentlyViewed] = useState(false);

    const isModernProfessionals = appFeatures?.[FeatureToggleHeaders.PROFESSIONALS] === "true" ? true : false;
    const isModernTenant = appFeatures?.[FeatureToggleHeaders.TENANT] === "true" ? true : false;

    const isOwners = getOwnersEnabled(appFeatures);

    const [searchTypeInfo, setSearchTypeInfo] = useState<SearchTypeInfo>({
        placeholder: resources.typeAhead.ALLPROPERTIES.placeholder(),
        prompt: resources.typeAhead.prompt.ALLPROPERTIES.label(),
        lastURI: "/search/all-properties/open-last-search",
    });
    const minimumInputLength = searchType === SearchTypeGQL.Ownerscloud ? 1 : 2;

    const culture = useCulture();
    //Recently Viewed Section -- START
    const { data: recentlyViewedFetched, loading: recentlyViewedLoading } = useIsomorphicQuery<{
        search: RecentlyViewedType;
    }>(RECENTLY_VIEWED_QUERY, {
        variables: { culture },
        skip: skipRecentlyViewed,
    });
    useEffect(() => {
        const recentlyViewedObject = convertRecentlyViewedArrayToObject(recentlyViewedFetched?.search as any);
        recentlyViewedObject && setRecentlyViewed(recentlyViewedObject);
        setSkipRecentlyViewed(!recentlyViewedLoading);
    }, [recentlyViewedFetched, recentlyViewedLoading]);

    type Products = keyof typeof searchTypeOptions;

    const isCountrySelectorVisible = useCallback(
        (searchType: SearchTypeGQL): searchType is Products => {
            if (["PROFESSIONALS"].includes(searchType) && isModernProfessionals) return false;
            else if (["TENANTS", "PROFESSIONALS"].includes(searchType)) return true;
            else return false;
        },
        [isModernProfessionals]
    );

    const displayUpsellModal = useCallback(
        (searchType: SearchTypeInfo) => {
            if (!searchType.productId) {
                return false;
            }
            const tab = navConfig.find(({ id }) => id === searchType.productId);

            if (searchType.subProductId) {
                const subTab = tab?.sections.find(({ id }) => id === searchType.subProductId);
                dispatch(setUpsellModal(subTab?.isUserSubscriber, searchType.subProductId));
                return subTab?.isUserSubscriber;
            } else {
                dispatch(setUpsellModal(tab?.isUserSubscriber, searchType.productId));
                return tab?.isUserSubscriber;
            }
        },
        [dispatch, navConfig]
    );

    const renderGroup = useCallback(
        (params: AutocompleteRenderGroupParams) => (
            <Box as="li" key={`list-item-group-${params.key}`}>
                <Box as="ul">
                    <RecentSearch
                        title={searchTypeInfo.prompt}
                        lastSearchURI={searchTypeInfo.lastURI}
                        hasLastSearch={hasSaveSearch(searchType)}
                        recentSearches={getRecentSearches()}
                        handleClick={searchItemClick}
                    />
                </Box>
                <Box as="ul">
                    <RecentlyViewed
                        title={resources.typeAhead.recentlyViewedPrompt.label()}
                        recentlyViewed={getRecentlyViewed()}
                        searchType={searchType}
                    />
                </Box>
            </Box>
        ),
        [searchType, searchTypeInfo, recentlyViewed]
    );

    const handleOnCountrySelectorChange = useCallback(country => {
        setCountryCodeSelected(country);
    }, []);

    const handleInputChange = useCallback(
        (_event: React.SyntheticEvent | undefined, value: string, _reason: AutocompleteInputChangeReason) => {
            setReset(value.length < minimumInputLength);
            setClearInput(false);
        },
        [minimumInputLength]
    );

    const searchTypeOptions = useMemo(() => {
        const defaultOptions = {
            [SearchTypeGQL.AllProperties]: {
                label: resources?.typeAhead.searchType.ALLPROPERTIES.label(),
                sortOrder: SearchType.ALLPROPERTIES,
            },
            [SearchTypeGQL.ForLease]: {
                label: resources?.typeAhead.searchType.FORLEASE.label(),
                sortOrder: SearchType.FORLEASE,
            },
            [SearchTypeGQL.ForSale]: {
                label: resources?.typeAhead.searchType.FORSALE.label(),
                sortOrder: SearchType.FORSALE,
            },
            [SearchTypeGQL.SaleComps]: {
                label: resources?.typeAhead.searchType.SALECOMPS.label(),
                sortOrder: SearchType.SALECOMPS,
            },
            [SearchTypeGQL.LeaseComps]: {
                label: resources?.typeAhead.searchType.LEASECOMPS.label(),
                sortOrder: SearchType.LEASECOMPS,
            },
            [SearchTypeGQL.PublicRecord]: {
                label: resources?.typeAhead.searchType.PUBLICRECORD.label(),
                sortOrder: SearchType.PUBLICRECORD,
            },
        };

        if (isModernProfessionals) {
            defaultOptions[SearchTypeGQL.DirectoryContacts] = {
                label: resources?.typeAhead.searchType.DIRECTORYCONTACTS.label(),
                sortOrder: SearchType.DIRECTORYCONTACTS,
            };
            defaultOptions[SearchTypeGQL.DirectoryLocations] = {
                label: resources?.typeAhead.searchType.DIRECTORYLOCATIONS.label(),
                sortOrder: SearchType.DIRECTORYLOCATIONS,
            };
            defaultOptions[SearchTypeGQL.DirectoryCompanies] = {
                label: resources?.typeAhead.searchType.DIRECTORYCOMPANIES.label(),
                sortOrder: SearchType.DIRECTORYCOMPANIES,
            };
        } else {
            defaultOptions[SearchTypeGQL.Professionals] = {
                label: resources?.typeAhead.searchType.PROFESSIONALS.label(),
                sortOrder: SearchType.PROFESSIONALS,
            };
        }

        defaultOptions[SearchTypeGQL.Fund] = {
            label: resources?.typeAhead.searchType.FUNDS.label(),
            sortOrder: SearchType.FUND,
        };

        if (isOwners) {
            defaultOptions[SearchTypeGQL.Ownerscloud] = {
                label: resources?.typeAhead.searchType.OWNERS.label(),
                sortOrder: SearchType.OWNERSCLOUD,
            };
        }

        const currentTenant = {};

        if (isModernTenant) {
            currentTenant[SearchTypeGQL.ModernTenant] = {
                label: resources?.typeAhead.searchType.MODERNTENANT.label(),
                sortOrder: SearchType.MODERNTENANT,
            };
            currentTenant[SearchTypeGQL.ModernTenantLocations] = {
                label: resources?.typeAhead.searchType.MODERNTENANTLOCATIONS.label(),
                sortOrder: SearchType.MODERNTENANTLOCATIONS,
            };
        } else {
            currentTenant[SearchTypeGQL.Tenants] = {
                label: resources?.typeAhead.searchType.TENANTS.label(),
                sortOrder: SearchType.TENANTS,
            };
        }

        return { ...defaultOptions, ...currentTenant };
    }, [isModernProfessionals, isModernTenant, resources?.typeAhead]);

    const { getLabel, getSecondary } = useOptionsText(resources);
    const getOptions = useCallback(
        (value: string) => {
            return new Promise<SearchItemOption[]>((resolve, _reject) => {
                queryPromise(OMNI_SEARCH_QUERY, {
                    variables: {
                        term: value,
                        searchType,
                        countryCode: showCountrySelector ? countryCodeSelected : null,
                    },
                }).then(response => {
                    const searchEntries = (response?.data?.search?.all?.entries ?? []) as SearchItem[];
                    if (searchEntries.length === 0) {
                        setReset(true);
                        return resolve([]);
                    }
                    const resultSet = searchEntries.map<SearchItemOption>(searchEntry => {
                        return {
                            ...searchEntry,
                            label: getLabel(searchEntry, value, searchType),
                            secondary: getSecondary(searchEntry, searchType),
                            term: value,
                        };
                    });
                    return resolve(resultSet);
                });
            });
        },
        [countryCodeSelected, getLabel, getSecondary, searchType, showCountrySelector]
    );

    /**
     * Checks local and session storage for saved searches
     * @param searchType Product search type
     * @returns true if there are previous searches, false otherwise
     */
    const hasSaveSearch = (searchType: SearchTypeGQL): boolean => {
        if (typeof window !== "undefined") {
            // in suite For Lease the same as All Properties
            searchType = searchType === SearchTypeGQL.ForLease ? SearchTypeGQL.AllProperties : searchType;
            return hasSearchKeys(window.localStorage, searchType) || hasSearchKeys(window.sessionStorage, searchType);
        }
        return false;
    };

    /**
     * Returns an object with search type's typeahead placeholder, recent search prompt, and last search uri
     * @param searchType Product search type
     * @returns an object with the typeahead placeholder, recent search prompt, and last search uri of search type
     */
    const getSearchTypeInfo = useCallback(
        (searchType: SearchTypeGQL): SearchTypeInfo => {
            switch (searchType) {
                case SearchTypeGQL.AllProperties:
                    return {
                        placeholder: resources.typeAhead.ALLPROPERTIES.placeholder(),
                        prompt: resources.typeAhead.prompt.ALLPROPERTIES.label(),
                        lastURI: "/search/all-properties/open-last-search",
                        productId: MainProductIds.PROPERTIES,
                        subProductId: SectionProductIds.ALL_PROPERTIES,
                    };
                case SearchTypeGQL.ForLease:
                    return {
                        placeholder: resources.typeAhead.FORLEASE.placeholder(),
                        prompt: resources.typeAhead.prompt.FORLEASE.label(),
                        lastURI: "/search/all-properties/open-last-search",
                        productId: MainProductIds.LEASING,
                    };
                case SearchTypeGQL.ForSale:
                    return {
                        placeholder: resources.typeAhead.FORSALE.placeholder(),
                        prompt: resources.typeAhead.prompt.FORSALE.label(),
                        lastURI: "/search/for-sale/open-last-search",
                        productId: MainProductIds.SALES,
                        subProductId: SectionProductIds.FOR_SALE,
                    };
                case SearchTypeGQL.LeaseComps:
                    return {
                        placeholder: resources.typeAhead.LEASECOMPS.placeholder(),
                        prompt: resources.typeAhead.prompt.LEASECOMPS.label(),
                        lastURI: "/LeaseComps/Search/open-last-search",
                        productId: MainProductIds.LEASING,
                        subProductId: SectionProductIds.LEASE_COMPS,
                    };
                case SearchTypeGQL.Professionals:
                    return {
                        placeholder: resources.typeAhead.PROFESSIONALS.placeholder(),
                        prompt: resources.typeAhead.prompt.PROFESSIONALS.label(),
                        lastURI: "",
                        productId: MainProductIds.LEGACY_PROFESSIONALS,
                    };
                case SearchTypeGQL.DirectoryContacts:
                    return {
                        placeholder: resources?.typeAhead.DIRECTORYCONTACT.placeholder(),
                        prompt: resources.typeAhead.prompt.DIRECTORYCONTACT.label(),
                        lastURI: "",
                        productId: MainProductIds.PROFESSIONALS,
                    };
                case SearchTypeGQL.DirectoryLocations:
                    return {
                        placeholder: resources?.typeAhead.DIRECTORYLOCATION.placeholder(),
                        prompt: resources.typeAhead.prompt.DIRECTORYLOCATION.label(),
                        lastURI: "",
                        productId: MainProductIds.PROFESSIONALS,
                    };
                case SearchTypeGQL.DirectoryCompanies:
                    return {
                        placeholder: resources?.typeAhead.DIRECTORYCOMPANY.placeholder(),
                        prompt: resources.typeAhead.prompt.DIRECTORYCOMPANY.label(),
                        lastURI: "",
                        productId: MainProductIds.PROFESSIONALS,
                    };
                case SearchTypeGQL.SaleComps:
                    return {
                        placeholder: resources.typeAhead.SALECOMPS.placeholder(),
                        prompt: resources.typeAhead.prompt.SALECOMPS.label(),
                        lastURI: "/search/sale-comps/open-last-search",
                        productId: MainProductIds.SALES,
                        subProductId: SectionProductIds.SALE_COMPS,
                    };
                case SearchTypeGQL.PublicRecord:
                    return {
                        placeholder: resources.typeAhead.PUBLICRECORD.placeholder(),
                        prompt: resources.typeAhead.prompt.PUBLICRECORD.label(),
                        lastURI: "/search/public-record/open-last-search",
                        productId: MainProductIds.PUBLIC_RECORD,
                    };
                case SearchTypeGQL.Tenants:
                    return {
                        placeholder: resources.typeAhead.TENANTS.placeholder(),
                        prompt: resources.typeAhead.prompt.TENANTS.label(),
                        lastURI: "",
                        productId: isModernTenant ? MainProductIds.TENANT : MainProductIds.LEGACY_TENANT,
                    };
                case SearchTypeGQL.ModernTenant:
                    return {
                        placeholder: resources.typeAhead.MODERNTENANT.placeholder(),
                        prompt: resources.typeAhead.prompt.MODERNTENANT.label(),
                        lastURI: "",
                        productId: MainProductIds.TENANT,
                    };
                case SearchTypeGQL.ModernTenantLocations:
                    return {
                        placeholder: resources.typeAhead.MODERNTENANTLOCATIONS.placeholder(),
                        prompt: resources.typeAhead.prompt.MODERNTENANTLOCATIONS.label(),
                        lastURI: "",
                        productId: MainProductIds.TENANT,
                    };
                case SearchTypeGQL.Fund:
                    return {
                        placeholder: resources.typeAhead.FUND.placeholder(),
                        prompt: resources.typeAhead.prompt.FUND.label(),
                        lastURI: "",
                        productId: MainProductIds.OWNER_COMPANIES,
                        subProductId: SectionProductIds.OWNERS_FUNDS,
                    };
                case SearchTypeGQL.Ownerscloud:
                    return {
                        placeholder: resources.typeAhead.OWNERS.placeholder(),
                        prompt: resources.typeAhead.prompt.OWNERS.label(),
                        lastURI: "",
                        productId: MainProductIds.OWNER_COMPANIES,
                    };
                default:
                    return {
                        placeholder: "",
                        prompt: "",
                        lastURI: "",
                    };
            }
        },
        [resources.typeAhead, isModernTenant]
    );

    const [clearInput, setClearInput] = useState<boolean>(false);
    const handleSelectedSearchType = useCallback(
        searchType => {
            const type = searchType as SearchTypeGQL;
            const searchTypeInfo = getSearchTypeInfo(type);

            if (displayUpsellModal(searchTypeInfo)) {
                if (showCountrySelector !== isCountrySelectorVisible(type)) setShowCountrySelector(prevVal => !prevVal);
                setSearchType(type);
                setSearchTypeInfo(searchTypeInfo);
                setClearInput(true); // toggling the clear input states allows us to clear the text field when we press on the search dropdown values
            } else {
                setSearchType(SearchTypeGQL["AllProperties"]);
            }
        },
        [showCountrySelector, isCountrySelectorVisible, getSearchTypeInfo, displayUpsellModal]
    );

    /**
     * Gets Recent Searches from local storage from specified searchType
     * @returns Array of SearchResult
     */
    const getRecentSearches = useCallback((): SearchItemOption[] => {
        let value = window.localStorage.getItem(recentSearchesKey);
        if (value == null || value === "") return [];
        value = JSON.parse(value);
        if (!value?.[searchType]) {
            return [];
        }
        return value?.[searchType] as SearchItemOption[];
    }, [searchType]);

    const getRecentlyViewed = useCallback((): SearchItemOption[] => {
        if (!recentlyViewed[searchType]) {
            return [];
        }

        return recentlyViewed[searchType].value?.list?.map(value => ({
            ...value,
            label: value.address,
        }));
    }, [recentlyViewed, searchType]);

    /**
     * Gets RecentSearches, adds new search to RecentSearches, removes duplicates,
     * then slices to 4 searches. Sets new Recent Searches to local storage
     * @param recentSearch new SearchResult to be added
     */
    const putRecentSearches = useCallback(
        (recentSearch: SearchItemOption) => {
            RecentSearches = getRecentSearches();
            RecentSearches.unshift(recentSearch);
            const uniqueSearches = new Set();
            const filteredSearches = RecentSearches.filter(obj => {
                let isInSet;
                if (searchType === SearchTypeGQL.Professionals || searchType === SearchTypeGQL.Tenants) {
                    isInSet = uniqueSearches.has(obj.label);
                    uniqueSearches.add(obj.label);
                } else {
                    isInSet = uniqueSearches.has(obj.redirect?.["href"]);
                    uniqueSearches.add(obj.redirect?.["href"]);
                }
                return !isInSet;
            });
            RecentSearches = filteredSearches;
            RecentSearches = RecentSearches.slice(0, 4);

            const value = JSON.parse(window.localStorage.getItem(recentSearchesKey) ?? "{}");
            value[searchType] = RecentSearches;

            window.localStorage.setItem(recentSearchesKey, JSON.stringify(value));
        },
        [getRecentSearches, searchType]
    );

    /**
     * Called when a search item or recent searches item is clicked
     */
    const searchItemClick = useCallback(
        (searchItem: SearchItemOption | null) => {
            const { redirect, item } = searchItem || {};
            if (searchType === SearchTypeGQL.Professionals && !isModernProfessionals) {
                const { Elements: { FullContactName = undefined, CompanyName = undefined } = {}, DisplayText } =
                    searchItem?.item ?? {};
                searchClassicProfessionals(
                    FullContactName,
                    CompanyName ?? DisplayText,
                    countryCodeSelected as any, // FIXME
                    navLinks.cpd
                ).then(() => {
                    putRecentSearches(searchItem as SearchItemOption);
                });
            } else if (searchType === SearchTypeGQL.Tenants) {
                // FIXME(don): Consolidate duplicated CountryCode types
                searchClassicTenant(client, item, countryCodeSelected as any, navLinks.fusionSearch)
                    .then(() => {
                        putRecentSearches(searchItem as SearchItemOption);
                    })
                    .catch(err => {
                        const error = toError(err);
                        logger.error(error);
                    });
            } else if (searchType === SearchTypeGQL.Ownerscloud) {
                putRecentSearches(searchItem as SearchItemOption);
                window.location.href = redirect?.["href"] ?? "";
            } else if (redirect && isSearchHrefRedirect(redirect)) {
                hrefRedirect(redirect);
                putRecentSearches(searchItem as SearchItemOption);
            } else {
                const error = new Error(`Typeahead failed to handle search for selected item`);
                Object.assign(error, {
                    searchType,
                    selectedItem: searchItem,
                    searchTypeInfo,
                });
                logger.error(error);
                //formRedirect(newValue?.redirect)
            }
        },
        [
            countryCodeSelected,
            isModernProfessionals,
            logger,
            navLinks.cpd,
            navLinks.fusionSearch,
            putRecentSearches,
            searchType,
            searchTypeInfo,
        ]
    );

    //This is a hack to avoid typeahead filtering records returned from GraphQL
    //ideally autocomplete should have a prop to disable filtering entirely
    const filterOptions = React.useMemo(
        () =>
            createFilterOptions({
                stringify: (option: SearchItemOption) => `${option?.label || "nolabel"}-${option?.term || "noterm"}`,
            }),
        []
    );

    const noOptions = useCallback(
        (props: BoxProps) => (
            <RecentSearch
                message={resources.typeAhead.prompt.noResults.label()}
                recentSearches={EMPTY_OPTIONS}
                handleClick={searchItemClick}
                {...props}
            />
        ),
        [searchItemClick, resources.typeAhead.prompt.noResults]
    );

    /**
     * Called when the selected search item changes. For our purposes, this means
     * that the user clicked on a search item and we need to navigate to that
     * entity's detail page.
     *
     * @param _event
     * @param newValue
     * @param reason
     *
     * @see {@link AsyncAutocompleteProps#onChange}
     */
    const onSearchItemChange = useCallback(
        (
            _event: React.SyntheticEvent<Element, Event> | undefined,
            newValue: SearchItemOption | null,
            _reason: AutocompleteChangeReason
        ) => {
            searchItemClick(newValue);
        },
        [searchItemClick]
    );
    return (
        <Flex overflow="hidden" textOverflow="ellipsis">
            {!showDetailOmniSearch && (
                <Select
                    automation-id="uui-typeahead-type-input"
                    initialSelection={searchType}
                    fullWidthPopover
                    selection={searchType}
                    popoverProps={popoverProps}
                    paneProps={paneProps}
                    minWidth="113px"
                    options={searchTypeOptions}
                    onChange={handleSelectedSearchType}
                    sx={sx}
                />
            )}
            <AsyncAutocomplete
                id="uui-typeahead-autocomplete"
                automation-id="uui-typeahead-autocomplete"
                getOptions={getOptions}
                loadingText={resources.typeAhead.search.loading()}
                forcePopupIcon="auto"
                placeholder={searchTypeInfo.placeholder}
                minimumInputLength={minimumInputLength}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                onInputChange={handleInputChange}
                selectOnFocus={false}
                clearOnBlur={clearInput}
                width="400px"
                popupIcon={searchIcon}
                onChange={onSearchItemChange}
                debounceTime={500}
                onClose={detailSearchClick}
                open={openSearch}
                renderOption={renderHighlightMatchSearchItem}
                // when resetting we need a new instance of an array to initiate an update of the option
                // using a static instance like EMPTY_OPTIONS would not trigger and update
                options={reset ? OptionsData : EMPTY_OPTIONS}
                filterOptions={filterOptions}
                groupBy={reset ? option => option.group ?? "" : undefined}
                renderGroup={reset ? renderGroup : undefined}
                componentsProps={componentsProps}
                noOptionsText={noOptions}
            />
            {showCountrySelector && (
                <Select
                    automation-id="uui-typeahead-country-code-input"
                    initialSelection={countryCodeSelected}
                    options={availableCountries}
                    onChange={handleOnCountrySelectorChange}
                    fullWidthPopover
                    paneProps={countrySelectorPaneProps}
                    popoverProps={countrySelectorPopoverProps}
                    minWidth="73px"
                />
            )}
        </Flex>
    );
};

function queryPromise(query: DocumentNode, options: QueryHookOptions) {
    return client.query({
        query,
        variables: options.variables,
    });
}

export default SearchTypeAhead;
