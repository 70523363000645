import React, { FC, useMemo } from "react";

import { Divider, Flex, Text } from "@costar/theme-ui";

import { ActionKey, ButtonState, SubNavState } from "../../../common/state";

import { TooltipRenderer } from "./tooltip/tooltip-renderer";
import {
    FavoriteButton,
    FavoritesModeButton,
    MoreButton,
    NextPreviousButtons,
    RemoveButton,
    ReportsButton,
    SaveSearchButton,
    SelectButton,
} from "./nav-action";

import NavbarButtons from "$client/auth/sub-menu/nav-action/navbar-buttons";

export type NavRightContentProps = Omit<SubNavState, "tabSelection" | "rightNavContent"> & {
    labels?: Partial<Record<Exclude<ActionKey, "tab">, string>>;
};

export const NavRightContent: FC<NavRightContentProps> = props => {
    const {
        actionButtonState,
        labels,
        activeRecordIndex,
        resultSetTotalCount,
        favoritesCount,
        resultSetTotalCountTooltipText,
        subnavLabel: message,
    } = props;
    const {
        select: selectButton = {} as ButtonState,
        remove: removeButton = {} as ButtonState,
        favorite: favoriteButton = {} as ButtonState,
        next: nextButton = {} as ButtonState,
        previous: previousButton = {} as ButtonState,
        reports: reportsButton = {} as ButtonState,
        save: saveButton = {} as ButtonState,
        more: moreButton = {} as ButtonState,
        navbarButtons = [] as ButtonState,
        favoritesMode: favoritesModeButton = {} as ButtonState,
    } = actionButtonState;
    const areCountersDefined = useMemo(() => {
        return activeRecordIndex != null && resultSetTotalCount != null;
    }, [activeRecordIndex, resultSetTotalCount]);
    const showNextAndPrevious = useMemo(() => {
        return !!nextButton.show && !!previousButton.show && areCountersDefined;
    }, [nextButton.show, previousButton.show, areCountersDefined]);

    const elementAfterMessage = !!showNextAndPrevious;
    const resultCountTooltipMessage = resultSetTotalCountTooltipText ?? "";
    const subNavButtonGroupFalse = !selectButton.show && !removeButton.show && !favoriteButton.show;
    const subNavButtonGroupTrue = selectButton.show || removeButton.show || favoriteButton.show;
    const saveReportsMoreGroupTrue = reportsButton.show || saveButton.show || moreButton.show;

    const searchViewResultCountLabel = useMemo(() => {
        const displayTooltip = resultSetTotalCountTooltipText && resultSetTotalCountTooltipText !== "";
        return (
            subNavButtonGroupFalse &&
            !saveButton.show &&
            !reportsButton.show &&
            !showNextAndPrevious &&
            message && (
                <Flex
                    alignItems="center"
                    automation-id="uui-submenu-label-tooltip"
                    height="100%"
                    pr={favoritesModeButton.show ? "" : "lg"}
                >
                    <Divider
                        orientation="vertical"
                        automation-id="uui-submenu-divider"
                        height="100%"
                        width="0.5px"
                        mr="sm"
                    />
                    <Text fontSize="sm" automation-id="uui-label" sx={{ whiteSpace: "nowrap" }}>
                        {message}
                    </Text>
                    {displayTooltip && <TooltipRenderer tooltipMessage={resultCountTooltipMessage} />}
                </Flex>
            )
        );
    }, [
        resultSetTotalCountTooltipText,
        subNavButtonGroupFalse,
        saveButton.show,
        reportsButton.show,
        showNextAndPrevious,
        message,
        favoritesModeButton.show,
        resultCountTooltipMessage,
    ]);

    const favoritesModeComponent = useMemo(() => {
        return (
            !!favoritesModeButton.show && (
                <Flex
                    alignItems="center"
                    justifyContent="flex-start"
                    gap="xs"
                    height="100%"
                    automation-id="uui-favorites-mode-icon"
                    pr="xxl"
                    bg={!!favoritesModeButton.toggled ? "primary.light" : "unset"}
                    borderTop="solid rgba(191, 191, 191, 0.85) 1px"
                    borderBottom="solid rgba(191, 191, 191, 0.85) 1px"
                >
                    <Divider
                        orientation="vertical"
                        height="100%"
                        width="0.5px"
                        automation-id="uui-favorites-mode-divider"
                    />
                    <FavoritesModeButton favoritesCount={favoritesCount} isToggled={!!favoritesModeButton.toggled} />
                </Flex>
            )
        );
    }, [favoritesCount, favoritesModeButton.show, favoritesModeButton?.toggled]);
    return (
        <Flex marginLeft="auto" height="34px" alignItems="center" gap="xs">
            {selectButton.show && <SelectButton toggled={selectButton.toggled} />}
            {removeButton.show && <RemoveButton />}
            {favoriteButton.show && <FavoriteButton toggled={favoriteButton.toggled} />}
            {subNavButtonGroupTrue && saveReportsMoreGroupTrue && (
                <Divider orientation="vertical" height="100%" width="0.5px" automation-id="uui-submenu-divider" />
            )}
            {saveButton.show && <SaveSearchButton />}
            {reportsButton.show && <ReportsButton />}
            {moreButton.show && <MoreButton />}
            <NavbarButtons navbarButtons={navbarButtons} />
            {(subNavButtonGroupTrue || saveReportsMoreGroupTrue || showNextAndPrevious) && message && (
                <>
                    <Divider orientation="vertical" automation-id="uui-submenu-divider" height="100%" width="0.5px" />
                    <Text fontSize="sm" automation-id="uui-label" pr={!showNextAndPrevious && "sm"}>
                        {message}
                    </Text>
                </>
            )}
            {searchViewResultCountLabel}
            {favoritesModeComponent}
            {showNextAndPrevious && (
                <>
                    <Divider
                        orientation="vertical"
                        automation-id="uui-submenu-divider"
                        height="100%"
                        width={elementAfterMessage ? "0.5px" : 0}
                    />
                    <NextPreviousButtons
                        {...{
                            previousButton: {
                                label: labels?.previous,
                                ...previousButton,
                            },
                            nextButton: {
                                label: labels?.next,
                                ...nextButton,
                            },
                            activeRecordIndex,
                        }}
                    />
                </>
            )}
        </Flex>
    );
};
