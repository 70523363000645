import { Resources } from "../../../../i18n/resources";
import { ROOT_URL } from "../../../common";

import { LeftNavContentProps } from "./types";

export default function buildLeftNavOptions(culturalResources: Resources, akamaiCulture?: string): LeftNavContentProps {
    const isUK = akamaiCulture === "en-GB";
    const baseUrl = isUK ? "https://www.costar.co.uk" : "https://www.costar.com";
    return {
        rootUrl: ROOT_URL,
        links: [
            {
                label: culturalResources.menu.productSolutions(),
                url: `${baseUrl}/overview`,
            },
            {
                label: culturalResources.menu.contact(),
                url: `${baseUrl}/contact`,
            },
            ...(isUK
                ? [
                      {
                          label: culturalResources.menu.resources(),
                          url: `${baseUrl}/resources`,
                      },
                  ]
                : []),
        ],
    };
}
