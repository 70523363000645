import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import urlJoin from "url-join";

import UpsellModal from "../../shared/upsell-modal";
import { AuthNavConfigData, useAuthNavConfigContext } from "../nav-config";

import { makeFetchMenuCallback, PostData, SiteNavigationMenu } from "./callbacks";
import { addGlanceIfNotPresent, emitScreenShareStartEvent, screenShareSessionClose } from "./glance";
import { useUniversalMenu } from "./use-universal-menu";

import costarLogo from "$assets/icons/logo.svg";
import { useStrictI18N, useVisible } from "$client/hooks";
import { ShareScreenModal } from "$client/shared/share-screen-modal";
import { appRoot, AuthenticatedClientConfig, useConfigContext } from "$common";

// TODO(don): base this off BASE_URL, provide via ClientConfig
const demoUrl = "https://www.costar.com/home/demo";

export interface HamburgerMenuProps {
    hamburgerIconRef: React.RefObject<HTMLElement>;
}

function wait(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const HamburgerMenu: FC<HamburgerMenuProps> = props => {
    const resources = useStrictI18N();

    const { hamburgerIconRef } = props;
    const menuContainerRef = useRef<HTMLDivElement>(null);

    const { loading, navConfig } = useAuthNavConfigContext();
    const navConfigContextRef = useRef<AuthNavConfigData>({ loading, navConfig });

    useEffect(() => {
        navConfigContextRef.current = { loading, navConfig };
    }, [loading, navConfig]);

    // ===================== USER DATA AND CLIENT CONFIG  ======================

    const config = useConfigContext() as AuthenticatedClientConfig;
    const {
        options: {
            // navLinks: { login: loginUrl, listingManager: listingUri, bizBuySell },
            navLinks,
            screenShare: { glanceSite },
        },
        userPreferences: { CultureName: culture = "en-US" } = {},
        appFeatures,
    } = config;
    // todo refactor
    // ============================== MENU DATA  ===============================

    // memoize fetchMenuCallback and save the returned data for use elsewhere.
    // This function is only ever called once so no parameter equality check
    // is necessary.
    const menuData = useRef<SiteNavigationMenu>();
    const fetchMenuCallback = useMemo(() => {
        const isLoading = (): Boolean => {
            return navConfigContextRef.current.loading;
        };

        const cachedFetchMenuCallback = async (postData: PostData): Promise<SiteNavigationMenu> => {
            while (isLoading()) {
                await wait(100);
            }

            if (menuData.current) {
                return menuData.current;
            }

            const response = await makeFetchMenuCallback(config, navConfigContextRef.current.navConfig)(postData);
            menuData.current = response;
            return menuData.current;
        };

        return cachedFetchMenuCallback;
    }, [config]);

    // ============================== UPSELL MODAL =============================

    const [isUpsellModalVisible, showUpsellModal, hideUpsellModal] = useVisible(false);

    /**
     * Override "currently selected tab" for sake of upselling.
     *
     * Set to a link id when user clicks on a sidebar menu link they don't have access to.
     * "Restored" to `undefined` when the upsell modal is closed.
     *
     * There is a close, but not exact, mapping between product ids and "link ids".
     * Difference has to do with business decisions that I'm not sure about and
     * hot-potato legacy migrations.
     */
    const [selectedNotAvailable, setSelectedNotAvailable] = useState<string | undefined>();
    const [isScreenShareModalOpen, setScreenShareModalOpen] = useState(false);
    const [screenShareCode, setScreenShareCode] = useState(resources.screenShare.modal.loading());

    const onUpsellSubmit = useCallback(() => {
        hideUpsellModal();
        window.open(demoUrl, "_blank");
    }, [hideUpsellModal]);

    const onUpsellClose = useCallback(() => {
        setSelectedNotAvailable(undefined);
        hideUpsellModal();
    }, [setSelectedNotAvailable, hideUpsellModal]);

    // =========================================================================

    // React-external setup logic to run (once) on mount
    useEffect(() => {
        addGlanceIfNotPresent(glanceSite);
        // eslint-disable-next-line react-hooks/exhaustive-deps -- should only ever run once
    }, []);

    const endSessionHandler = useCallback(() => {
        setScreenShareModalOpen(false);
        screenShareSessionClose();
        setScreenShareCode(resources.screenShare.modal.loading());
    }, [resources.screenShare.modal]);

    const closeModalHandler = useCallback(() => {
        setScreenShareModalOpen(false);
    }, []);

    // It would be great to put this into callbacks.ts
    const menuCreate = useCallback(async (container: HTMLElement | null | undefined) => {
        // const { config } = context;
        /* Modify menu items marked as "inactive" so that clicking them displays
         * an upsell modal. Note that, since menuData is set by fetchMenuCallback
         * and fetchMenuCallback is always called before menuCreate, if menuData.callback
         * is undefined here there is a bug.
         */
        if (menuData.current) {
            const notActivated = menuData.current.NotActivated;
            if (notActivated && Array.isArray(notActivated) && notActivated.length > 0) {
                container &&
                    notActivated.forEach(id => {
                        // const itemMatches = container.querySelectorAll(`li[id^=${id}] > a`);
                        // if (!itemMatches?.length || itemMatches.length === 0) {
                        //     return;
                        // }
                        const notActivatedLink = container.querySelector<HTMLAnchorElement>(`li[id^=${id}] > a`);
                        if (!notActivatedLink) return;
                        notActivatedLink.href = "#";
                        notActivatedLink.onclick = () => {
                            // this.activeNavId = id.toUpperCase();
                            // this.showModal = true;
                            setSelectedNotAvailable(id.toUpperCase());
                            showUpsellModal();
                        };
                    });
            }

            /* If container is undefined here, there's _definitely_ a bug
             */
            if (container) {
                const preferencesMenuItemMatches =
                    container.querySelector<HTMLAnchorElement>("li[id^=My_Preferences] > a");
                const screenShareMatches = container.querySelectorAll<HTMLAnchorElement>("li[id^=Screen_Share] > a");
                const BattleOfBidsItemMatches =
                    container.querySelector<HTMLAnchorElement>("li[id^=BattleOfTheBids] > a");
                const AccountManagerItemMatches =
                    container.querySelector<HTMLAnchorElement>("li[id^=AccountManager] > a");
                const BuyerDashboardItemMatches =
                    container.querySelector<HTMLAnchorElement>("li[id^=BuyerDashboard] > a");
                if (preferencesMenuItemMatches) {
                    const navigateToCentralPreferences = () => {
                        // TODO: Handle session storage feature toggle
                        const goBackUrl = window?.location?.href || "";
                        const centralPrefUrl: string = `${navLinks.preferences}?goBackUrl=${goBackUrl}`;
                        window?.location?.assign(centralPrefUrl);
                    };
                    preferencesMenuItemMatches.href = "#";
                    preferencesMenuItemMatches.onclick = navigateToCentralPreferences;
                }
                if (screenShareMatches && screenShareMatches.length > 0) {
                    screenShareMatches[0].href = "#cobrowse";
                    screenShareMatches[0].onclick = () => {
                        setScreenShareModalOpen(true);
                        emitScreenShareStartEvent(setScreenShareCode, closeModalHandler);
                        return false;
                    };
                }
                if (BattleOfBidsItemMatches != null) {
                    BattleOfBidsItemMatches.href = navLinks.battleOfTheBids;
                }
                if (AccountManagerItemMatches != null) {
                    AccountManagerItemMatches.href = navLinks.accountManager;
                }
                if (BuyerDashboardItemMatches != null) {
                    BuyerDashboardItemMatches.href = navLinks.buyerDashboard;
                }
                const KnowledgeCenter = container.querySelector<HTMLAnchorElement>("li[id^=Knowledge_Center] > a");
                if (KnowledgeCenter) {
                    KnowledgeCenter.target = "_blank";
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- never called twice, so no updates needed
    }, []);

    useUniversalMenu(hamburgerIconRef, menuContainerRef, {
        onCreated: menuCreate,
        fetchMenuCallback,
        appId: 2,
        culture, // TODO: copied from uui. Needs to become dynamic. Can this be a hyphen-separated culture code?
        logo: costarLogo,
        gatewayUri: navLinks.login, // TODO(don): should this be navLinks.centralAuth?,
        listingUri: navLinks.listingManager,
        homeUri: urlJoin(navLinks.login, "home"),
        pdsUri: navLinks.pds,
        legacyGatewayUri: navLinks.legacyGateway,
        authUri: navLinks.centralAuth,
        leaseDcfUri: navLinks.leaseDcf,
        loopNetUri: navLinks.loopNet,
        showCaseUri: navLinks.showCase,
        // contactId,
    });

    return (
        <>
            <UpsellModal
                open={isUpsellModalVisible}
                onClose={onUpsellClose}
                loginUrl={navLinks.login}
                productKey={selectedNotAvailable}
            />
            <div ref={menuContainerRef} />
            <ShareScreenModal
                isOpen={isScreenShareModalOpen}
                endSessionAndClose={endSessionHandler}
                closeModal={closeModalHandler}
                code={screenShareCode}
            />
        </>
    );
};

// const getMenuContainer = (): HTMLElement | null => document?.querySelector(".uui-hamburger-menu") ?? null;
