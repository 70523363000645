import React, { Dispatch, FC, ProviderProps, useContext, useEffect, useMemo, useReducer } from "react";

import subject from "@csgp-mfe-utils/event-observer";

import { UUISubject, UUISubjectPayloads } from "../../client/subjects";

import {
    ACTIONS,
    hideRequestTraining,
    NavAction,
    setBackButtonOverrideLabel,
    setPagerCount,
    showActionButton,
    ShowActiveProductKey,
    showAlertsButton,
    showAlertsCount,
    showFavoritesCount,
    showOmniSearch,
    showResultSetTotalCountTooltipText,
    showSubNav,
    showSubNavLabel,
    toggleActionButton,
} from "./reducer/state.actions";
import { stateReducer } from "./reducer/state.reducer";
import type { NavState, SetPagerCountKeys, ShowButtonKeys, ToggleButtonKeys } from "./state";

export type ApplicationState = NavState & {
    dispatch: Dispatch<NavAction>;
};

const StateContext = React.createContext<ApplicationState>({} as ApplicationState);

// Always show display name for context, even in production. Makes debugging
// significantly easier. NOTE: this is only done for several highly important
// context providers. It shouldn't be done for every component.
StateContext.displayName = "StateContext";

export type ShowKeySubject = Partial<Record<ShowButtonKeys, `${UUISubject}`>>;
export type ToggleKeySubject = Partial<Record<ToggleButtonKeys, `${UUISubject}`>>;
export type SetPagerCounts = Record<SetPagerCountKeys, `${UUISubject}`>;

const showButtonAttributes: ShowKeySubject = {
    back: UUISubject.ShowBackButton,
    select: UUISubject.ShowCheckMarkButton,
    remove: UUISubject.ShowRemoveButton,
    favorite: UUISubject.ShowFavoriteButton,
    save: UUISubject.ShowSaveButton,
    more: UUISubject.ShowMoreButton,
    help: UUISubject.ShowHelpButton,
    reports: UUISubject.ShowReportsButton,
    navbarButtons: UUISubject.NavbarButtons,
    favoritesMode: UUISubject.ShowFavoritesModeButton,
};

const toggleButtonAttributes: ToggleKeySubject = {
    select: UUISubject.ShowCheckMarkButtonChecked,
    favorite: UUISubject.ShowFavoriteButtonChecked,
    favoritesMode: UUISubject.ShowFavoritesModeButtonChecked,
};

const setPagerCounts: SetPagerCounts = {
    activeRecordIndex: UUISubject.ActiveRecordIndex,
    resultSetTotalCount: UUISubject.ResultSetTotalCount,
};

export const StateProvider: FC<ProviderProps<NavState>> = ({ value: initialValue, children }) => {
    const [state, dispatch] = useReducer(stateReducer, initialValue);

    useEffect(() => {
        const toDetachShowButton = Object.entries(showButtonAttributes).map(([buttonKey, buttonSubject]) =>
            subject<boolean>(buttonSubject).attach(value => {
                dispatch(showActionButton(buttonKey as ShowButtonKeys, value));
            })
        );

        const toDetachToggleButton = Object.entries(toggleButtonAttributes).map(([buttonKey, buttonSubject]) =>
            subject<boolean>(buttonSubject).attach(value => {
                dispatch(toggleActionButton(buttonKey as ToggleButtonKeys, value));
            })
        );

        const toDetachSetPagerCount = Object.entries(setPagerCounts).map(([counterKey, counterSubject]) =>
            subject<number>(counterSubject).attach(value => {
                dispatch(setPagerCount(counterKey as SetPagerCountKeys, value));
            })
        );

        const detachBackButtonOverrideLabel = subject<UUISubjectPayloads[UUISubject.BackButtonOverrideLabel]>(
            UUISubject.BackButtonOverrideLabel
        ).attach(value => {
            dispatch(setBackButtonOverrideLabel(value));
        });

        const detachShowSubNav = subject<UUISubjectPayloads[UUISubject.ShowSubNav]>(UUISubject.ShowSubNav).attach(
            value => {
                dispatch(showSubNav(value));
            }
        );

        const detachShowOmniSearch = subject<UUISubjectPayloads[UUISubject.ShowOmniSearch]>(
            UUISubject.ShowOmniSearch
        ).attach(value => {
            dispatch(showOmniSearch(value));
        });

        const detachHideRequestTraining = subject<UUISubjectPayloads[UUISubject.HideRequestTraining]>(
            UUISubject.HideRequestTraining
        ).attach(value => {
            dispatch(hideRequestTraining(value));
        });

        const detachShowAlertsBtn = subject<UUISubjectPayloads[UUISubject.ShowAlertsButton]>(
            UUISubject.ShowAlertsButton
        ).attach(value => {
            dispatch(showAlertsButton(value));
        });

        const detachAlertsCount = subject<UUISubjectPayloads[UUISubject.AlertsCount]>(UUISubject.AlertsCount).attach(
            value => {
                dispatch(showAlertsCount(value));
            }
        );
        const detachSubnavLabel = subject<UUISubjectPayloads[UUISubject.SetSubnavLabel]>(
            UUISubject.SetSubnavLabel
        ).attach(value => {
            dispatch(showSubNavLabel(value));
        });

        const detachFavoritesCount = subject<UUISubjectPayloads[UUISubject.SetFavoritesCount]>(
            UUISubject.SetFavoritesCount
        ).attach(value => {
            dispatch(showFavoritesCount(value));
        });

        const detachResultSetTotalCountTooltipText = subject<
            UUISubjectPayloads[UUISubject.ResultSetTotalCountTooltipText]
        >(UUISubject.ResultSetTotalCountTooltipText).attach(value => {
            dispatch(showResultSetTotalCountTooltipText(value));
        });

        const detachActiveProductKey = subject<UUISubjectPayloads[UUISubject.ActiveProductKey]>( // Used only FOR LEASE / LEASING only
            UUISubject.ActiveProductKey
        ).attach(value => {
            dispatch(ShowActiveProductKey(value));
        });
        const detachDetailedEventClick = subject<UUISubjectPayloads[UUISubject.DetailedClickEvent]>(
            UUISubject.DetailedClickEvent
        ).attach(value => {
            dispatch({ type: ACTIONS.DetailedNavigationEvent, value });
        });

        return () => {
            toDetachShowButton.forEach(detach => detach());
            toDetachToggleButton.forEach(detach => detach());
            toDetachSetPagerCount.forEach(detach => detach());
            detachShowSubNav();
            detachShowOmniSearch();
            detachShowAlertsBtn();
            detachAlertsCount();
            detachSubnavLabel();
            detachFavoritesCount();
            detachResultSetTotalCountTooltipText();
            detachActiveProductKey();
            detachDetailedEventClick();
            detachHideRequestTraining();
            detachBackButtonOverrideLabel();
        };
    }, [dispatch]);

    const applicationState = useMemo(
        () => ({
            ...state,
            dispatch,
        }),
        [state, dispatch]
    );

    return <StateContext.Provider value={applicationState}>{children}</StateContext.Provider>;
};

export const StateConsumer = StateContext.Consumer;

/**
 * Provides access to the entirety of the application's state.
 *
 * Application state is stored in the {@link StateContext}. Consuming components
 * must be a descendant of a {@link StateProvider}.
 *
 * @api
 *
 * @returns The application state
 */
export const useAppState = () => useContext(StateContext);

/**
 * Provides access to masthead-specific state.
 *
 * @returns Current masthead state
 *
 * @see {@link useAppState} to access all application state.
 * @see {@link MastheadState}
 */
export const useMastheadState = () => {
    const { masthead, dispatch } = useAppState();
    return { masthead, dispatch };
};

/**
 * Provides access to navbar-specific state.
 *
 * @returns Current navbar state
 *
 * @see {@link useAppState} to access all application state.
 * @see {@link MainNavState}
 */
export const useNavState = () => {
    const { nav, dispatch } = useAppState();
    return { nav, dispatch };
};

/**
 * Provides access to subnav-specific state.
 *
 * @returns Current subnav state
 *
 * @see {@link useAppState} to access all application state.
 * @see {@link SubNavState}
 */
export const useSubnavState = () => {
    const { subnav, dispatch } = useAppState();
    return { subnav, dispatch };
};

/**
 * Provides access to upsell specific state.
 *
 * @returns Current upsell modal state
 *
 * @see {@link useAppState} to access all application state.
 * @see {@link UpsellState}
 */
export const useUpsellState = () => {
    const { upsell, dispatch } = useAppState();
    return { upsell, dispatch };
};
