import React, { FC, useCallback, useRef } from "react";

import subject from "@csgp-mfe-utils/event-observer";

import { useBoolean } from "@costar/theme-ui";
import { PopoverSelectContainer } from "@costar/theme-ui-select";

import { useStrictI18N } from "../../../hooks";
import { UUISubject, UUISubjectPayloads } from "../../../subjects";

const SaveSearchButton: FC = () => {
    const [isPopoverOpen, popoverOpenAction] = useBoolean();

    const saveButtonRef = useRef<HTMLButtonElement>(null);

    const culturalResources = useStrictI18N();

    const handleOnSaveClick = useCallback(() => {
        subject<UUISubjectPayloads[UUISubject.SaveButtonClicked]>(UUISubject.SaveButtonClicked).notify({
            container: saveButtonRef.current,
            open: isPopoverOpen,
        });
        popoverOpenAction.on();
    }, [isPopoverOpen, popoverOpenAction]);

    const handleOnSaveClose = useCallback(() => popoverOpenAction.off(), [popoverOpenAction]);

    return (
        <PopoverSelectContainer
            ref={saveButtonRef}
            id={"costar-save-button"}
            role={"uui-save-button"}
            automation-id={"uui-save-button"}
            sx={{
                height: "28px",
                fontSize: "xs",
            }}
            onClose={handleOnSaveClose}
            icon={"mui-arrowDropDown"}
            px={"xs"}
            open={isPopoverOpen}
            onOpen={handleOnSaveClick}
            paneProps={{
                width: "auto",
                fontSize: "xs",
            }}
            popoverProps={{
                disableScrollLock: true,
                children: <span data-testid={"popover-save-container"} />,
            }}
        >
            {culturalResources?.submenu?.detail?.label?.save()}
        </PopoverSelectContainer>
    );
};

export default React.memo(SaveSearchButton);
