/* eslint-disable */
import { DeepPartial } from "$graphql/core";
import { CoStar } from "@costar-gen/data-services-pds/types";
import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from "graphql";
import gql from "graphql-tag";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    JSON: Record<string, any>;
    MatchType: CoStar.Api.Lookup.MatchType;
}

export interface AppFeatureInput {
    readonly filters?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
}

export interface AppFeatureItem {
    readonly __typename?: "AppFeatureItem";
    readonly enabled?: Maybe<Scalars["Boolean"]>;
    readonly id?: Maybe<Scalars["ID"]>;
    readonly name: Scalars["String"];
}

export interface AppFeatureResult {
    readonly __typename?: "AppFeatureResult";
    readonly entries: ReadonlyArray<AppFeatureItem>;
}

export enum CountryCode {
    Ca = "CA",
    De = "DE",
    Es = "ES",
    Fr = "FR",
    Gb = "GB",
    Us = "US",
}

export interface FeaturesInput {
    readonly preqinEnabled?: InputMaybe<Scalars["Boolean"]>;
}

export interface HamburgerMenu {
    readonly __typename?: "HamburgerMenu";
    readonly ApplicationId?: Maybe<Scalars["Int"]>;
    readonly IsActivated?: Maybe<Scalars["Boolean"]>;
    readonly MarketingBrandId?: Maybe<Scalars["Int"]>;
    readonly MenuAttributes?: Maybe<ReadonlyArray<Maybe<MenuAttribute>>>;
    readonly MenuAttributesDisplay?: Maybe<ReadonlyArray<Maybe<MenuAttributeDisplay>>>;
    readonly MenuId?: Maybe<Scalars["Int"]>;
    readonly MenuItemGroups?: Maybe<ReadonlyArray<Maybe<Scalars["String"]>>>;
    readonly MenuItems?: Maybe<ReadonlyArray<Maybe<MenuItem>>>;
    readonly NotActivated?: Maybe<ReadonlyArray<Maybe<Scalars["String"]>>>;
    readonly ViewName?: Maybe<Scalars["String"]>;
}

export interface MenuAttribute {
    readonly __typename?: "MenuAttribute";
    readonly MenuAttributeId?: Maybe<Scalars["Int"]>;
    readonly MenuAttributeName?: Maybe<Scalars["String"]>;
    readonly MenuAttributeValue?: Maybe<Scalars["String"]>;
    readonly MenuAttributeValueType?: Maybe<Scalars["String"]>;
    readonly MenuId?: Maybe<Scalars["Int"]>;
}

export interface MenuAttributeDisplay {
    readonly __typename?: "MenuAttributeDisplay";
    readonly Culture?: Maybe<Scalars["String"]>;
    readonly DisplayAttributeName?: Maybe<Scalars["String"]>;
    readonly DisplayAttributeValue?: Maybe<Scalars["String"]>;
    readonly MenuAttributeDisplayId?: Maybe<Scalars["Int"]>;
    readonly MenuId?: Maybe<Scalars["Int"]>;
}

export interface MenuItem {
    readonly __typename?: "MenuItem";
    readonly CheckEntitlement?: Maybe<Scalars["Boolean"]>;
    readonly CheckUserAttribute?: Maybe<Scalars["Boolean"]>;
    readonly ChildItems?: Maybe<ReadonlyArray<Maybe<MenuItem>>>;
    readonly DisplayOrder?: Maybe<Scalars["Int"]>;
    readonly GroupName?: Maybe<Scalars["String"]>;
    readonly IsActivated?: Maybe<Scalars["Boolean"]>;
    readonly LevelId?: Maybe<Scalars["Int"]>;
    readonly MenuItemAttributes?: Maybe<ReadonlyArray<Maybe<MenuItemAttribute>>>;
    readonly MenuItemDisplayName?: Maybe<Scalars["String"]>;
    readonly MenuItemDisplayNames?: Maybe<ReadonlyArray<Maybe<MenuItemDisplayName>>>;
    readonly MenuItemEntitlements?: Maybe<ReadonlyArray<Maybe<MenuItemEntitlement>>>;
    readonly MenuItemGroupAttributes?: Maybe<ReadonlyArray<Maybe<Scalars["String"]>>>;
    readonly MenuItemId?: Maybe<Scalars["Int"]>;
    readonly MenuItemName?: Maybe<Scalars["String"]>;
    readonly MenuItemParentId?: Maybe<Scalars["Int"]>;
    readonly MenuItemTypeId?: Maybe<Scalars["Int"]>;
    readonly MenuItemTypeName?: Maybe<Scalars["String"]>;
    readonly MenuItemUrl?: Maybe<Scalars["String"]>;
    readonly MenuItemUserAttributes?: Maybe<ReadonlyArray<Maybe<Scalars["String"]>>>;
}

export interface MenuItemAttribute {
    readonly __typename?: "MenuItemAttribute";
    readonly MenuItemAttributeId?: Maybe<Scalars["Int"]>;
    readonly MenuItemAttributeName?: Maybe<Scalars["String"]>;
    readonly MenuItemAttributeValue?: Maybe<Scalars["String"]>;
    readonly MenuItemId?: Maybe<Scalars["Int"]>;
}

export interface MenuItemDisplayName {
    readonly __typename?: "MenuItemDisplayName";
    readonly Culture?: Maybe<Scalars["String"]>;
    readonly DisplayAttributeName?: Maybe<Scalars["String"]>;
    readonly DisplayAttributeValue?: Maybe<Scalars["String"]>;
    readonly MenuItemDisplayAttributeId?: Maybe<Scalars["Int"]>;
    readonly MenuItemId?: Maybe<Scalars["Int"]>;
}

export interface MenuItemEntitlement {
    readonly __typename?: "MenuItemEntitlement";
    readonly EntitlementTypeId?: Maybe<Scalars["Int"]>;
    readonly EntitlementTypeName?: Maybe<Scalars["String"]>;
    readonly MenuItemEntitlementMappingId?: Maybe<Scalars["Int"]>;
    readonly MenuItemId?: Maybe<Scalars["Int"]>;
    readonly Value?: Maybe<Scalars["String"]>;
    readonly ValueType?: Maybe<Scalars["String"]>;
}

export interface Query {
    readonly __typename?: "Query";
    readonly getUserInfo?: Maybe<UserInfo>;
    readonly search: SearchQuery;
    readonly user: User;
}

export interface RecentlyViewed {
    readonly __typename?: "RecentlyViewed";
    readonly userEntities?: Maybe<ReadonlyArray<Maybe<UserEntities>>>;
}

export interface RecentlyViewedValues {
    readonly __typename?: "RecentlyViewedValues";
    readonly list?: Maybe<ReadonlyArray<RecentlyViewedValuesList>>;
}

export interface RecentlyViewedValuesList {
    readonly __typename?: "RecentlyViewedValuesList";
    readonly address?: Maybe<Scalars["String"]>;
    readonly id: Scalars["String"];
    readonly idType?: Maybe<Scalars["String"]>;
    readonly lvt?: Maybe<Scalars["String"]>;
    readonly redirect?: Maybe<SearchHrefRedirect>;
}

export interface SearchFormRedirect {
    readonly __typename?: "SearchFormRedirect";
    readonly action: Scalars["String"];
    readonly formData?: Maybe<Scalars["JSON"]>;
    readonly method: Scalars["String"];
}

export interface SearchHrefRedirect {
    readonly __typename?: "SearchHrefRedirect";
    readonly href: Scalars["String"];
}

export interface SearchInput {
    readonly countryCode?: InputMaybe<Scalars["String"]>;
    readonly secondaryLookup?: InputMaybe<Scalars["Boolean"]>;
    readonly take?: InputMaybe<Scalars["Int"]>;
    readonly term?: InputMaybe<Scalars["String"]>;
    readonly type?: InputMaybe<SearchType>;
}

/**
 * A single typeahead search result item. This could be a property, a fund, a
 * person, etc. depending on which {@link SearchType product} was searched.
 */
export interface SearchItem {
    readonly __typename?: "SearchItem";
    readonly country?: Maybe<Scalars["String"]>;
    readonly htmlDescription?: Maybe<Scalars["String"]>;
    readonly htmlName: Scalars["String"];
    readonly id: Scalars["ID"];
    readonly item?: Maybe<Scalars["JSON"]>;
    readonly matchType: Scalars["Int"];
    readonly name: Scalars["String"];
    readonly propertyType?: Maybe<Scalars["String"]>;
    readonly redirect?: Maybe<SearchRedirect>;
    readonly type: SearchItemType;
}

export enum SearchItemType {
    ForSale = "FOR_SALE",
    LeaseComp = "LEASE_COMP",
    Property = "PROPERTY",
}

export interface SearchQuery {
    readonly __typename?: "SearchQuery";
    readonly all: SearchResult;
    readonly criteria: Scalars["String"];
    readonly healthCheck?: Maybe<Scalars["Boolean"]>;
    readonly recentlyViewed: RecentlyViewed;
    readonly recents: SearchResult;
}

export interface SearchQueryAllArgs {
    features?: InputMaybe<FeaturesInput>;
    input?: InputMaybe<SearchInput>;
}

export interface SearchQueryCriteriaArgs {
    item: Scalars["String"];
    productType: Scalars["Int"];
    searchType: SearchType;
}

export interface SearchQueryRecentlyViewedArgs {
    culture?: InputMaybe<Scalars["String"]>;
}

export interface SearchQueryRecentsArgs {
    input?: InputMaybe<SearchInput>;
}

export type SearchRedirect = SearchFormRedirect | SearchHrefRedirect;

export interface SearchResult {
    readonly __typename?: "SearchResult";
    readonly entries: ReadonlyArray<SearchItem>;
}

export enum SearchType {
    AllProperties = "ALL_PROPERTIES",
    DirectoryCompanies = "DIRECTORY_COMPANIES",
    DirectoryContacts = "DIRECTORY_CONTACTS",
    DirectoryLocations = "DIRECTORY_LOCATIONS",
    ForLease = "FOR_LEASE",
    ForSale = "FOR_SALE",
    Fund = "FUND",
    LeaseComps = "LEASE_COMPS",
    ModernTenant = "MODERN_TENANT",
    ModernTenantLocations = "MODERN_TENANT_LOCATIONS",
    MultiFamily = "MULTI_FAMILY",
    Ownerscloud = "OWNERSCLOUD",
    Professionals = "PROFESSIONALS",
    PublicRecord = "PUBLIC_RECORD",
    SaleComps = "SALE_COMPS",
    Tenants = "TENANTS",
}

export interface User {
    readonly __typename?: "User";
    readonly appFeatures: ReadonlyArray<AppFeatureItem>;
    readonly apps: ReadonlyArray<UserApp>;
    readonly culture: Scalars["String"];
    readonly hamburgerMenu?: Maybe<HamburgerMenu>;
    readonly headers: UserHeaders;
    readonly info?: Maybe<UserInfo>;
    readonly isAuth: Scalars["Boolean"];
    readonly uuiEnabled: Scalars["Boolean"];
}

export interface UserAppsArgs {
    input?: InputMaybe<UserAppInput>;
}

export interface UserHamburgerMenuArgs {
    ApplicationId?: InputMaybe<Scalars["Int"]>;
    Culture?: InputMaybe<Scalars["String"]>;
    MarketingBrandId?: InputMaybe<Scalars["Int"]>;
}

/** A product or group of products that the User may or may not have access to. Used to populate the NavConfig. */
export interface UserApp {
    readonly __typename?: "UserApp";
    readonly alternatePaths?: Maybe<ReadonlyArray<Scalars["String"]>>;
    readonly countriesSubscriptions?: Maybe<ReadonlyArray<Scalars["String"]>>;
    readonly defaultHref?: Maybe<Scalars["String"]>;
    readonly hoverItems?: Maybe<ReadonlyArray<UserAppSection>>;
    readonly href?: Maybe<Scalars["String"]>;
    readonly i18nKey?: Maybe<Scalars["String"]>;
    readonly id: Scalars["ID"];
    readonly ignorePaths?: Maybe<ReadonlyArray<Scalars["String"]>>;
    readonly isActive?: Maybe<Scalars["Boolean"]>;
    readonly isUserSubscriber?: Maybe<Scalars["Boolean"]>;
    readonly sections: ReadonlyArray<UserAppSection>;
    readonly text?: Maybe<Scalars["String"]>;
}

export interface UserAppInput {
    readonly activeProductKey?: InputMaybe<Scalars["String"]>;
    readonly culture?: InputMaybe<Scalars["String"]>;
}

/**
 * A subnav menu tab. This belongs to a containing {@link UserApp} and usually
 * corresponds to a specific product group.
 */
export interface UserAppSection {
    readonly __typename?: "UserAppSection";
    readonly alternatePaths?: Maybe<ReadonlyArray<Scalars["String"]>>;
    readonly baseUrl?: Maybe<Scalars["String"]>;
    readonly dropdownItems?: Maybe<ReadonlyArray<UserAppSectionDropdownItem>>;
    readonly href?: Maybe<Scalars["String"]>;
    readonly i18nKey?: Maybe<Scalars["String"]>;
    readonly id: Scalars["ID"];
    readonly ignorePaths?: Maybe<ReadonlyArray<Scalars["String"]>>;
    readonly isUserSubscriber?: Maybe<Scalars["Boolean"]>;
    readonly landingPath?: Maybe<Scalars["String"]>;
    readonly text?: Maybe<Scalars["String"]>;
}

export interface UserAppSectionDropdownItem {
    readonly __typename?: "UserAppSectionDropdownItem";
    readonly alternatePaths?: Maybe<ReadonlyArray<Scalars["String"]>>;
    readonly baseUrl?: Maybe<Scalars["String"]>;
    readonly href?: Maybe<Scalars["String"]>;
    readonly i18nKey?: Maybe<Scalars["String"]>;
    readonly id?: Maybe<Scalars["ID"]>;
    readonly ignorePaths?: Maybe<ReadonlyArray<Scalars["String"]>>;
    readonly isHeader?: Maybe<Scalars["Boolean"]>;
    readonly isUserSubscriber?: Maybe<Scalars["Boolean"]>;
    readonly landingPath?: Maybe<Scalars["String"]>;
    readonly text?: Maybe<Scalars["String"]>;
}

/**
 * User entities are single-concern data specific to a user. They are stored in/
 * managed by the Central User Entities (CUE) service.
 *
 * User entities primarily handled by the UUI are recently viewed properties. These
 * appear within the Omnisearch/Typeahead search dropdown.
 *
 * @see [CUE Wiki](https://wiki.costargroup.com/pages/viewpage.action?pageId=554187954)
 */
export interface UserEntities {
    readonly __typename?: "UserEntities";
    readonly UserEntityId?: Maybe<Scalars["String"]>;
    readonly entityTag?: Maybe<Scalars["String"]>;
    readonly groupTag?: Maybe<Scalars["String"]>;
    readonly instance?: Maybe<Scalars["String"]>;
    readonly isDefault?: Maybe<Scalars["Boolean"]>;
    readonly productType?: Maybe<Scalars["String"]>;
    readonly status?: Maybe<Scalars["Int"]>;
    readonly updatedTime?: Maybe<Scalars["String"]>;
    readonly value: RecentlyViewedValues;
    readonly verticalTag?: Maybe<Scalars["String"]>;
}

export interface UserEntitlementInput {
    readonly countryCode?: InputMaybe<ReadonlyArray<CountryCode>>;
    readonly marketIds?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
    readonly productCodes?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
    readonly productIds?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
    readonly propertyTypeIds?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
}

export interface UserEntitlementInputResult {
    readonly __typename?: "UserEntitlementInputResult";
    readonly entries: ReadonlyArray<UserProductEntitlement>;
}

export interface UserHeaders {
    readonly __typename?: "UserHeaders";
    readonly leaseCompsEnabled: Scalars["Boolean"];
    readonly professionalsEnabled: Scalars["Boolean"];
    readonly tenantEnabled?: Maybe<Scalars["Boolean"]>;
}

export interface UserInfo {
    readonly __typename?: "UserInfo";
    readonly companyId?: Maybe<Scalars["String"]>;
    readonly companyName?: Maybe<Scalars["String"]>;
    readonly contactId: Scalars["String"];
    readonly email?: Maybe<Scalars["String"]>;
    readonly entitlements: UserEntitlementInputResult;
    readonly id: Scalars["ID"];
    readonly locationCountryCode?: Maybe<Scalars["String"]>;
    readonly name?: Maybe<Scalars["String"]>;
    readonly preferences: UserPreferenceResult;
    readonly rootCompanyId?: Maybe<Scalars["String"]>;
    readonly salesUnits: UserSalesUnitInputResult;
    readonly worksForCoStar?: Maybe<Scalars["Boolean"]>;
}

export interface UserInfoEntitlementsArgs {
    input?: InputMaybe<UserEntitlementInput>;
}

export interface UserInfoSalesUnitsArgs {
    input: UserSalesUnitInput;
}

export interface UserPreference {
    readonly __typename?: "UserPreference";
    readonly identifier: Scalars["String"];
    readonly isDefault?: Maybe<Scalars["Boolean"]>;
    readonly value: Scalars["Int"];
}

export interface UserPreferenceInput {
    readonly identifiers?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
}

export interface UserPreferenceResult {
    readonly __typename?: "UserPreferenceResult";
    readonly entries?: Maybe<ReadonlyArray<UserPreference>>;
}

export interface UserProduct {
    readonly __typename?: "UserProduct";
    readonly code: Scalars["String"];
    readonly id: Scalars["ID"];
}

export interface UserProductEntitlement {
    readonly __typename?: "UserProductEntitlement";
    readonly markets?: Maybe<ReadonlyArray<UserProductMarket>>;
    readonly product?: Maybe<UserProduct>;
    readonly propertyTypes?: Maybe<ReadonlyArray<UserProductLicense>>;
}

export interface UserProductLicense {
    readonly __typename?: "UserProductLicense";
    readonly dataSetCode?: Maybe<Scalars["String"]>;
    readonly dataSetDescription?: Maybe<Scalars["String"]>;
    readonly productMarketId?: Maybe<Scalars["String"]>;
    readonly propertyTypeId?: Maybe<Scalars["String"]>;
}

export interface UserProductMarket {
    readonly __typename?: "UserProductMarket";
    readonly countryCode?: Maybe<Scalars["String"]>;
    readonly name?: Maybe<Scalars["String"]>;
    readonly productMarketId?: Maybe<Scalars["String"]>;
}

export interface UserSalesUnit {
    readonly __typename?: "UserSalesUnit";
    readonly cd: Scalars["String"];
}

export interface UserSalesUnitInput {
    readonly productId: Scalars["String"];
}

export interface UserSalesUnitInputResult {
    readonly __typename?: "UserSalesUnitInputResult";
    readonly entries: ReadonlyArray<UserSalesUnit>;
}

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
    resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
    | ResolverFn<TResult, TParent, TContext, TArgs>
    | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
    parent: TParent,
    args: TArgs,
    context: TContext,
    info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
    parent: TParent,
    args: TArgs,
    context: TContext,
    info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
    parent: TParent,
    args: TArgs,
    context: TContext,
    info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
    subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
    resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
    subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
    resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
    | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
    | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
    | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
    | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
    parent: TParent,
    context: TContext,
    info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
    obj: T,
    context: TContext,
    info: GraphQLResolveInfo
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
    next: NextResolverFn<TResult>,
    parent: TParent,
    args: TArgs,
    context: TContext,
    info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
    AppFeatureInput: ResolverTypeWrapper<DeepPartial<AppFeatureInput>>;
    AppFeatureItem: ResolverTypeWrapper<DeepPartial<AppFeatureItem>>;
    AppFeatureResult: ResolverTypeWrapper<DeepPartial<AppFeatureResult>>;
    Boolean: ResolverTypeWrapper<DeepPartial<Scalars["Boolean"]>>;
    CountryCode: ResolverTypeWrapper<DeepPartial<CountryCode>>;
    FeaturesInput: ResolverTypeWrapper<DeepPartial<FeaturesInput>>;
    HamburgerMenu: ResolverTypeWrapper<DeepPartial<HamburgerMenu>>;
    ID: ResolverTypeWrapper<DeepPartial<Scalars["ID"]>>;
    Int: ResolverTypeWrapper<DeepPartial<Scalars["Int"]>>;
    JSON: ResolverTypeWrapper<DeepPartial<Scalars["JSON"]>>;
    MatchType: ResolverTypeWrapper<DeepPartial<Scalars["MatchType"]>>;
    MenuAttribute: ResolverTypeWrapper<DeepPartial<MenuAttribute>>;
    MenuAttributeDisplay: ResolverTypeWrapper<DeepPartial<MenuAttributeDisplay>>;
    MenuItem: ResolverTypeWrapper<DeepPartial<MenuItem>>;
    MenuItemAttribute: ResolverTypeWrapper<DeepPartial<MenuItemAttribute>>;
    MenuItemDisplayName: ResolverTypeWrapper<DeepPartial<MenuItemDisplayName>>;
    MenuItemEntitlement: ResolverTypeWrapper<DeepPartial<MenuItemEntitlement>>;
    Query: ResolverTypeWrapper<{}>;
    RecentlyViewed: ResolverTypeWrapper<DeepPartial<RecentlyViewed>>;
    RecentlyViewedValues: ResolverTypeWrapper<DeepPartial<RecentlyViewedValues>>;
    RecentlyViewedValuesList: ResolverTypeWrapper<DeepPartial<RecentlyViewedValuesList>>;
    SearchFormRedirect: ResolverTypeWrapper<DeepPartial<SearchFormRedirect>>;
    SearchHrefRedirect: ResolverTypeWrapper<DeepPartial<SearchHrefRedirect>>;
    SearchInput: ResolverTypeWrapper<DeepPartial<SearchInput>>;
    SearchItem: ResolverTypeWrapper<
        DeepPartial<Omit<SearchItem, "redirect"> & { redirect?: Maybe<ResolversTypes["SearchRedirect"]> }>
    >;
    SearchItemType: ResolverTypeWrapper<DeepPartial<SearchItemType>>;
    SearchQuery: ResolverTypeWrapper<DeepPartial<SearchQuery>>;
    SearchRedirect: DeepPartial<ResolversTypes["SearchFormRedirect"] | ResolversTypes["SearchHrefRedirect"]>;
    SearchResult: ResolverTypeWrapper<DeepPartial<SearchResult>>;
    SearchType: ResolverTypeWrapper<DeepPartial<SearchType>>;
    String: ResolverTypeWrapper<DeepPartial<Scalars["String"]>>;
    User: ResolverTypeWrapper<DeepPartial<User>>;
    UserApp: ResolverTypeWrapper<DeepPartial<UserApp>>;
    UserAppInput: ResolverTypeWrapper<DeepPartial<UserAppInput>>;
    UserAppSection: ResolverTypeWrapper<DeepPartial<UserAppSection>>;
    UserAppSectionDropdownItem: ResolverTypeWrapper<DeepPartial<UserAppSectionDropdownItem>>;
    UserEntities: ResolverTypeWrapper<DeepPartial<UserEntities>>;
    UserEntitlementInput: ResolverTypeWrapper<DeepPartial<UserEntitlementInput>>;
    UserEntitlementInputResult: ResolverTypeWrapper<DeepPartial<UserEntitlementInputResult>>;
    UserHeaders: ResolverTypeWrapper<DeepPartial<UserHeaders>>;
    UserInfo: ResolverTypeWrapper<DeepPartial<UserInfo>>;
    UserPreference: ResolverTypeWrapper<DeepPartial<UserPreference>>;
    UserPreferenceInput: ResolverTypeWrapper<DeepPartial<UserPreferenceInput>>;
    UserPreferenceResult: ResolverTypeWrapper<DeepPartial<UserPreferenceResult>>;
    UserProduct: ResolverTypeWrapper<DeepPartial<UserProduct>>;
    UserProductEntitlement: ResolverTypeWrapper<DeepPartial<UserProductEntitlement>>;
    UserProductLicense: ResolverTypeWrapper<DeepPartial<UserProductLicense>>;
    UserProductMarket: ResolverTypeWrapper<DeepPartial<UserProductMarket>>;
    UserSalesUnit: ResolverTypeWrapper<DeepPartial<UserSalesUnit>>;
    UserSalesUnitInput: ResolverTypeWrapper<DeepPartial<UserSalesUnitInput>>;
    UserSalesUnitInputResult: ResolverTypeWrapper<DeepPartial<UserSalesUnitInputResult>>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
    AppFeatureInput: DeepPartial<AppFeatureInput>;
    AppFeatureItem: DeepPartial<AppFeatureItem>;
    AppFeatureResult: DeepPartial<AppFeatureResult>;
    Boolean: DeepPartial<Scalars["Boolean"]>;
    FeaturesInput: DeepPartial<FeaturesInput>;
    HamburgerMenu: DeepPartial<HamburgerMenu>;
    ID: DeepPartial<Scalars["ID"]>;
    Int: DeepPartial<Scalars["Int"]>;
    JSON: DeepPartial<Scalars["JSON"]>;
    MatchType: DeepPartial<Scalars["MatchType"]>;
    MenuAttribute: DeepPartial<MenuAttribute>;
    MenuAttributeDisplay: DeepPartial<MenuAttributeDisplay>;
    MenuItem: DeepPartial<MenuItem>;
    MenuItemAttribute: DeepPartial<MenuItemAttribute>;
    MenuItemDisplayName: DeepPartial<MenuItemDisplayName>;
    MenuItemEntitlement: DeepPartial<MenuItemEntitlement>;
    Query: {};
    RecentlyViewed: DeepPartial<RecentlyViewed>;
    RecentlyViewedValues: DeepPartial<RecentlyViewedValues>;
    RecentlyViewedValuesList: DeepPartial<RecentlyViewedValuesList>;
    SearchFormRedirect: DeepPartial<SearchFormRedirect>;
    SearchHrefRedirect: DeepPartial<SearchHrefRedirect>;
    SearchInput: DeepPartial<SearchInput>;
    SearchItem: DeepPartial<
        Omit<SearchItem, "redirect"> & { redirect?: Maybe<ResolversParentTypes["SearchRedirect"]> }
    >;
    SearchQuery: DeepPartial<SearchQuery>;
    SearchRedirect: DeepPartial<
        ResolversParentTypes["SearchFormRedirect"] | ResolversParentTypes["SearchHrefRedirect"]
    >;
    SearchResult: DeepPartial<SearchResult>;
    String: DeepPartial<Scalars["String"]>;
    User: DeepPartial<User>;
    UserApp: DeepPartial<UserApp>;
    UserAppInput: DeepPartial<UserAppInput>;
    UserAppSection: DeepPartial<UserAppSection>;
    UserAppSectionDropdownItem: DeepPartial<UserAppSectionDropdownItem>;
    UserEntities: DeepPartial<UserEntities>;
    UserEntitlementInput: DeepPartial<UserEntitlementInput>;
    UserEntitlementInputResult: DeepPartial<UserEntitlementInputResult>;
    UserHeaders: DeepPartial<UserHeaders>;
    UserInfo: DeepPartial<UserInfo>;
    UserPreference: DeepPartial<UserPreference>;
    UserPreferenceInput: DeepPartial<UserPreferenceInput>;
    UserPreferenceResult: DeepPartial<UserPreferenceResult>;
    UserProduct: DeepPartial<UserProduct>;
    UserProductEntitlement: DeepPartial<UserProductEntitlement>;
    UserProductLicense: DeepPartial<UserProductLicense>;
    UserProductMarket: DeepPartial<UserProductMarket>;
    UserSalesUnit: DeepPartial<UserSalesUnit>;
    UserSalesUnitInput: DeepPartial<UserSalesUnitInput>;
    UserSalesUnitInputResult: DeepPartial<UserSalesUnitInputResult>;
};

export type AppFeatureItemResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes["AppFeatureItem"] = ResolversParentTypes["AppFeatureItem"]
> = {
    enabled?: Resolver<Maybe<ResolversTypes["Boolean"]>, ParentType, ContextType>;
    id?: Resolver<Maybe<ResolversTypes["ID"]>, ParentType, ContextType>;
    name?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppFeatureResultResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes["AppFeatureResult"] = ResolversParentTypes["AppFeatureResult"]
> = {
    entries?: Resolver<ReadonlyArray<ResolversTypes["AppFeatureItem"]>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HamburgerMenuResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes["HamburgerMenu"] = ResolversParentTypes["HamburgerMenu"]
> = {
    ApplicationId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
    IsActivated?: Resolver<Maybe<ResolversTypes["Boolean"]>, ParentType, ContextType>;
    MarketingBrandId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
    MenuAttributes?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes["MenuAttribute"]>>>, ParentType, ContextType>;
    MenuAttributesDisplay?: Resolver<
        Maybe<ReadonlyArray<Maybe<ResolversTypes["MenuAttributeDisplay"]>>>,
        ParentType,
        ContextType
    >;
    MenuId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
    MenuItemGroups?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes["String"]>>>, ParentType, ContextType>;
    MenuItems?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes["MenuItem"]>>>, ParentType, ContextType>;
    NotActivated?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes["String"]>>>, ParentType, ContextType>;
    ViewName?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes["JSON"], any> {
    name: "JSON";
}

export interface MatchTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes["MatchType"], any> {
    name: "MatchType";
}

export type MenuAttributeResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes["MenuAttribute"] = ResolversParentTypes["MenuAttribute"]
> = {
    MenuAttributeId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
    MenuAttributeName?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    MenuAttributeValue?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    MenuAttributeValueType?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    MenuId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MenuAttributeDisplayResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes["MenuAttributeDisplay"] = ResolversParentTypes["MenuAttributeDisplay"]
> = {
    Culture?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    DisplayAttributeName?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    DisplayAttributeValue?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    MenuAttributeDisplayId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
    MenuId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MenuItemResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes["MenuItem"] = ResolversParentTypes["MenuItem"]
> = {
    CheckEntitlement?: Resolver<Maybe<ResolversTypes["Boolean"]>, ParentType, ContextType>;
    CheckUserAttribute?: Resolver<Maybe<ResolversTypes["Boolean"]>, ParentType, ContextType>;
    ChildItems?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes["MenuItem"]>>>, ParentType, ContextType>;
    DisplayOrder?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
    GroupName?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    IsActivated?: Resolver<Maybe<ResolversTypes["Boolean"]>, ParentType, ContextType>;
    LevelId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
    MenuItemAttributes?: Resolver<
        Maybe<ReadonlyArray<Maybe<ResolversTypes["MenuItemAttribute"]>>>,
        ParentType,
        ContextType
    >;
    MenuItemDisplayName?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    MenuItemDisplayNames?: Resolver<
        Maybe<ReadonlyArray<Maybe<ResolversTypes["MenuItemDisplayName"]>>>,
        ParentType,
        ContextType
    >;
    MenuItemEntitlements?: Resolver<
        Maybe<ReadonlyArray<Maybe<ResolversTypes["MenuItemEntitlement"]>>>,
        ParentType,
        ContextType
    >;
    MenuItemGroupAttributes?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes["String"]>>>, ParentType, ContextType>;
    MenuItemId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
    MenuItemName?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    MenuItemParentId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
    MenuItemTypeId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
    MenuItemTypeName?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    MenuItemUrl?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    MenuItemUserAttributes?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes["String"]>>>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MenuItemAttributeResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes["MenuItemAttribute"] = ResolversParentTypes["MenuItemAttribute"]
> = {
    MenuItemAttributeId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
    MenuItemAttributeName?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    MenuItemAttributeValue?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    MenuItemId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MenuItemDisplayNameResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes["MenuItemDisplayName"] = ResolversParentTypes["MenuItemDisplayName"]
> = {
    Culture?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    DisplayAttributeName?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    DisplayAttributeValue?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    MenuItemDisplayAttributeId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
    MenuItemId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MenuItemEntitlementResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes["MenuItemEntitlement"] = ResolversParentTypes["MenuItemEntitlement"]
> = {
    EntitlementTypeId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
    EntitlementTypeName?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    MenuItemEntitlementMappingId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
    MenuItemId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
    Value?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    ValueType?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes["Query"] = ResolversParentTypes["Query"]
> = {
    getUserInfo?: Resolver<Maybe<ResolversTypes["UserInfo"]>, ParentType, ContextType>;
    search?: Resolver<ResolversTypes["SearchQuery"], ParentType, ContextType>;
    user?: Resolver<ResolversTypes["User"], ParentType, ContextType>;
};

export type RecentlyViewedResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes["RecentlyViewed"] = ResolversParentTypes["RecentlyViewed"]
> = {
    userEntities?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes["UserEntities"]>>>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecentlyViewedValuesResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes["RecentlyViewedValues"] = ResolversParentTypes["RecentlyViewedValues"]
> = {
    list?: Resolver<Maybe<ReadonlyArray<ResolversTypes["RecentlyViewedValuesList"]>>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecentlyViewedValuesListResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes["RecentlyViewedValuesList"] = ResolversParentTypes["RecentlyViewedValuesList"]
> = {
    address?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    id?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
    idType?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    lvt?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    redirect?: Resolver<Maybe<ResolversTypes["SearchHrefRedirect"]>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SearchFormRedirectResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes["SearchFormRedirect"] = ResolversParentTypes["SearchFormRedirect"]
> = {
    action?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
    formData?: Resolver<Maybe<ResolversTypes["JSON"]>, ParentType, ContextType>;
    method?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SearchHrefRedirectResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes["SearchHrefRedirect"] = ResolversParentTypes["SearchHrefRedirect"]
> = {
    href?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SearchItemResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes["SearchItem"] = ResolversParentTypes["SearchItem"]
> = {
    country?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    htmlDescription?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    htmlName?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
    id?: Resolver<ResolversTypes["ID"], ParentType, ContextType>;
    item?: Resolver<Maybe<ResolversTypes["JSON"]>, ParentType, ContextType>;
    matchType?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
    name?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
    propertyType?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    redirect?: Resolver<Maybe<ResolversTypes["SearchRedirect"]>, ParentType, ContextType>;
    type?: Resolver<ResolversTypes["SearchItemType"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SearchQueryResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes["SearchQuery"] = ResolversParentTypes["SearchQuery"]
> = {
    all?: Resolver<ResolversTypes["SearchResult"], ParentType, ContextType, Partial<SearchQueryAllArgs>>;
    criteria?: Resolver<
        ResolversTypes["String"],
        ParentType,
        ContextType,
        RequireFields<SearchQueryCriteriaArgs, "item" | "productType" | "searchType">
    >;
    healthCheck?: Resolver<Maybe<ResolversTypes["Boolean"]>, ParentType, ContextType>;
    recentlyViewed?: Resolver<
        ResolversTypes["RecentlyViewed"],
        ParentType,
        ContextType,
        Partial<SearchQueryRecentlyViewedArgs>
    >;
    recents?: Resolver<ResolversTypes["SearchResult"], ParentType, ContextType, Partial<SearchQueryRecentsArgs>>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SearchRedirectResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes["SearchRedirect"] = ResolversParentTypes["SearchRedirect"]
> = {
    __resolveType: TypeResolveFn<"SearchFormRedirect" | "SearchHrefRedirect", ParentType, ContextType>;
};

export type SearchResultResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes["SearchResult"] = ResolversParentTypes["SearchResult"]
> = {
    entries?: Resolver<ReadonlyArray<ResolversTypes["SearchItem"]>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes["User"] = ResolversParentTypes["User"]
> = {
    appFeatures?: Resolver<ReadonlyArray<ResolversTypes["AppFeatureItem"]>, ParentType, ContextType>;
    apps?: Resolver<ReadonlyArray<ResolversTypes["UserApp"]>, ParentType, ContextType, Partial<UserAppsArgs>>;
    culture?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
    hamburgerMenu?: Resolver<
        Maybe<ResolversTypes["HamburgerMenu"]>,
        ParentType,
        ContextType,
        Partial<UserHamburgerMenuArgs>
    >;
    headers?: Resolver<ResolversTypes["UserHeaders"], ParentType, ContextType>;
    info?: Resolver<Maybe<ResolversTypes["UserInfo"]>, ParentType, ContextType>;
    isAuth?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
    uuiEnabled?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserAppResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes["UserApp"] = ResolversParentTypes["UserApp"]
> = {
    alternatePaths?: Resolver<Maybe<ReadonlyArray<ResolversTypes["String"]>>, ParentType, ContextType>;
    countriesSubscriptions?: Resolver<Maybe<ReadonlyArray<ResolversTypes["String"]>>, ParentType, ContextType>;
    defaultHref?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    hoverItems?: Resolver<Maybe<ReadonlyArray<ResolversTypes["UserAppSection"]>>, ParentType, ContextType>;
    href?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    i18nKey?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    id?: Resolver<ResolversTypes["ID"], ParentType, ContextType>;
    ignorePaths?: Resolver<Maybe<ReadonlyArray<ResolversTypes["String"]>>, ParentType, ContextType>;
    isActive?: Resolver<Maybe<ResolversTypes["Boolean"]>, ParentType, ContextType>;
    isUserSubscriber?: Resolver<Maybe<ResolversTypes["Boolean"]>, ParentType, ContextType>;
    sections?: Resolver<ReadonlyArray<ResolversTypes["UserAppSection"]>, ParentType, ContextType>;
    text?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserAppSectionResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes["UserAppSection"] = ResolversParentTypes["UserAppSection"]
> = {
    alternatePaths?: Resolver<Maybe<ReadonlyArray<ResolversTypes["String"]>>, ParentType, ContextType>;
    baseUrl?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    dropdownItems?: Resolver<
        Maybe<ReadonlyArray<ResolversTypes["UserAppSectionDropdownItem"]>>,
        ParentType,
        ContextType
    >;
    href?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    i18nKey?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    id?: Resolver<ResolversTypes["ID"], ParentType, ContextType>;
    ignorePaths?: Resolver<Maybe<ReadonlyArray<ResolversTypes["String"]>>, ParentType, ContextType>;
    isUserSubscriber?: Resolver<Maybe<ResolversTypes["Boolean"]>, ParentType, ContextType>;
    landingPath?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    text?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserAppSectionDropdownItemResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes["UserAppSectionDropdownItem"] = ResolversParentTypes["UserAppSectionDropdownItem"]
> = {
    alternatePaths?: Resolver<Maybe<ReadonlyArray<ResolversTypes["String"]>>, ParentType, ContextType>;
    baseUrl?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    href?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    i18nKey?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    id?: Resolver<Maybe<ResolversTypes["ID"]>, ParentType, ContextType>;
    ignorePaths?: Resolver<Maybe<ReadonlyArray<ResolversTypes["String"]>>, ParentType, ContextType>;
    isHeader?: Resolver<Maybe<ResolversTypes["Boolean"]>, ParentType, ContextType>;
    isUserSubscriber?: Resolver<Maybe<ResolversTypes["Boolean"]>, ParentType, ContextType>;
    landingPath?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    text?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserEntitiesResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes["UserEntities"] = ResolversParentTypes["UserEntities"]
> = {
    UserEntityId?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    entityTag?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    groupTag?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    instance?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    isDefault?: Resolver<Maybe<ResolversTypes["Boolean"]>, ParentType, ContextType>;
    productType?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    status?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
    updatedTime?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    value?: Resolver<ResolversTypes["RecentlyViewedValues"], ParentType, ContextType>;
    verticalTag?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserEntitlementInputResultResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes["UserEntitlementInputResult"] = ResolversParentTypes["UserEntitlementInputResult"]
> = {
    entries?: Resolver<ReadonlyArray<ResolversTypes["UserProductEntitlement"]>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserHeadersResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes["UserHeaders"] = ResolversParentTypes["UserHeaders"]
> = {
    leaseCompsEnabled?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
    professionalsEnabled?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
    tenantEnabled?: Resolver<Maybe<ResolversTypes["Boolean"]>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserInfoResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes["UserInfo"] = ResolversParentTypes["UserInfo"]
> = {
    companyId?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    companyName?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    contactId?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
    email?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    entitlements?: Resolver<
        ResolversTypes["UserEntitlementInputResult"],
        ParentType,
        ContextType,
        Partial<UserInfoEntitlementsArgs>
    >;
    id?: Resolver<ResolversTypes["ID"], ParentType, ContextType>;
    locationCountryCode?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    name?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    preferences?: Resolver<ResolversTypes["UserPreferenceResult"], ParentType, ContextType>;
    rootCompanyId?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    salesUnits?: Resolver<
        ResolversTypes["UserSalesUnitInputResult"],
        ParentType,
        ContextType,
        RequireFields<UserInfoSalesUnitsArgs, "input">
    >;
    worksForCoStar?: Resolver<Maybe<ResolversTypes["Boolean"]>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserPreferenceResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes["UserPreference"] = ResolversParentTypes["UserPreference"]
> = {
    identifier?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
    isDefault?: Resolver<Maybe<ResolversTypes["Boolean"]>, ParentType, ContextType>;
    value?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserPreferenceResultResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes["UserPreferenceResult"] = ResolversParentTypes["UserPreferenceResult"]
> = {
    entries?: Resolver<Maybe<ReadonlyArray<ResolversTypes["UserPreference"]>>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserProductResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes["UserProduct"] = ResolversParentTypes["UserProduct"]
> = {
    code?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
    id?: Resolver<ResolversTypes["ID"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserProductEntitlementResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes["UserProductEntitlement"] = ResolversParentTypes["UserProductEntitlement"]
> = {
    markets?: Resolver<Maybe<ReadonlyArray<ResolversTypes["UserProductMarket"]>>, ParentType, ContextType>;
    product?: Resolver<Maybe<ResolversTypes["UserProduct"]>, ParentType, ContextType>;
    propertyTypes?: Resolver<Maybe<ReadonlyArray<ResolversTypes["UserProductLicense"]>>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserProductLicenseResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes["UserProductLicense"] = ResolversParentTypes["UserProductLicense"]
> = {
    dataSetCode?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    dataSetDescription?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    productMarketId?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    propertyTypeId?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserProductMarketResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes["UserProductMarket"] = ResolversParentTypes["UserProductMarket"]
> = {
    countryCode?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    name?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    productMarketId?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserSalesUnitResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes["UserSalesUnit"] = ResolversParentTypes["UserSalesUnit"]
> = {
    cd?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserSalesUnitInputResultResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes["UserSalesUnitInputResult"] = ResolversParentTypes["UserSalesUnitInputResult"]
> = {
    entries?: Resolver<ReadonlyArray<ResolversTypes["UserSalesUnit"]>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
    AppFeatureItem?: AppFeatureItemResolvers<ContextType>;
    AppFeatureResult?: AppFeatureResultResolvers<ContextType>;
    HamburgerMenu?: HamburgerMenuResolvers<ContextType>;
    JSON?: GraphQLScalarType;
    MatchType?: GraphQLScalarType;
    MenuAttribute?: MenuAttributeResolvers<ContextType>;
    MenuAttributeDisplay?: MenuAttributeDisplayResolvers<ContextType>;
    MenuItem?: MenuItemResolvers<ContextType>;
    MenuItemAttribute?: MenuItemAttributeResolvers<ContextType>;
    MenuItemDisplayName?: MenuItemDisplayNameResolvers<ContextType>;
    MenuItemEntitlement?: MenuItemEntitlementResolvers<ContextType>;
    Query?: QueryResolvers<ContextType>;
    RecentlyViewed?: RecentlyViewedResolvers<ContextType>;
    RecentlyViewedValues?: RecentlyViewedValuesResolvers<ContextType>;
    RecentlyViewedValuesList?: RecentlyViewedValuesListResolvers<ContextType>;
    SearchFormRedirect?: SearchFormRedirectResolvers<ContextType>;
    SearchHrefRedirect?: SearchHrefRedirectResolvers<ContextType>;
    SearchItem?: SearchItemResolvers<ContextType>;
    SearchQuery?: SearchQueryResolvers<ContextType>;
    SearchRedirect?: SearchRedirectResolvers<ContextType>;
    SearchResult?: SearchResultResolvers<ContextType>;
    User?: UserResolvers<ContextType>;
    UserApp?: UserAppResolvers<ContextType>;
    UserAppSection?: UserAppSectionResolvers<ContextType>;
    UserAppSectionDropdownItem?: UserAppSectionDropdownItemResolvers<ContextType>;
    UserEntities?: UserEntitiesResolvers<ContextType>;
    UserEntitlementInputResult?: UserEntitlementInputResultResolvers<ContextType>;
    UserHeaders?: UserHeadersResolvers<ContextType>;
    UserInfo?: UserInfoResolvers<ContextType>;
    UserPreference?: UserPreferenceResolvers<ContextType>;
    UserPreferenceResult?: UserPreferenceResultResolvers<ContextType>;
    UserProduct?: UserProductResolvers<ContextType>;
    UserProductEntitlement?: UserProductEntitlementResolvers<ContextType>;
    UserProductLicense?: UserProductLicenseResolvers<ContextType>;
    UserProductMarket?: UserProductMarketResolvers<ContextType>;
    UserSalesUnit?: UserSalesUnitResolvers<ContextType>;
    UserSalesUnitInputResult?: UserSalesUnitInputResultResolvers<ContextType>;
};

export type UniversalUiContextQueryVariables = Exact<{
    activeProductKey?: InputMaybe<Scalars["String"]>;
}>;

export type UniversalUiContextQuery = { readonly __typename?: "Query" } & {
    readonly user: { readonly __typename?: "User" } & Pick<User, "isAuth" | "culture" | "uuiEnabled"> & {
            readonly info?: Maybe<
                { readonly __typename?: "UserInfo" } & Pick<
                    UserInfo,
                    | "id"
                    | "name"
                    | "worksForCoStar"
                    | "email"
                    | "companyId"
                    | "rootCompanyId"
                    | "companyName"
                    | "contactId"
                > & {
                        readonly preferences: { readonly __typename?: "UserPreferenceResult" } & {
                            readonly entries?: Maybe<
                                ReadonlyArray<
                                    { readonly __typename?: "UserPreference" } & Pick<
                                        UserPreference,
                                        "identifier" | "value" | "isDefault"
                                    >
                                >
                            >;
                        };
                    }
            >;
            readonly headers: { readonly __typename?: "UserHeaders" } & Pick<
                UserHeaders,
                "professionalsEnabled" | "leaseCompsEnabled" | "tenantEnabled"
            >;
            readonly appFeatures: ReadonlyArray<
                { readonly __typename?: "AppFeatureItem" } & Pick<AppFeatureItem, "id" | "name" | "enabled">
            >;
            readonly apps: ReadonlyArray<
                { readonly __typename?: "UserApp" } & Pick<
                    UserApp,
                    | "id"
                    | "i18nKey"
                    | "href"
                    | "isUserSubscriber"
                    | "alternatePaths"
                    | "countriesSubscriptions"
                    | "ignorePaths"
                    | "isActive"
                > & {
                        readonly sections: ReadonlyArray<
                            { readonly __typename?: "UserAppSection" } & Pick<
                                UserAppSection,
                                | "id"
                                | "i18nKey"
                                | "landingPath"
                                | "baseUrl"
                                | "href"
                                | "alternatePaths"
                                | "ignorePaths"
                                | "isUserSubscriber"
                            > & {
                                    readonly dropdownItems?: Maybe<
                                        ReadonlyArray<
                                            { readonly __typename?: "UserAppSectionDropdownItem" } & Pick<
                                                UserAppSectionDropdownItem,
                                                | "id"
                                                | "isHeader"
                                                | "i18nKey"
                                                | "landingPath"
                                                | "baseUrl"
                                                | "alternatePaths"
                                                | "ignorePaths"
                                                | "href"
                                                | "isUserSubscriber"
                                            >
                                        >
                                    >;
                                }
                        >;
                        readonly hoverItems?: Maybe<
                            ReadonlyArray<
                                { readonly __typename?: "UserAppSection" } & Pick<
                                    UserAppSection,
                                    "id" | "i18nKey" | "landingPath" | "baseUrl" | "href" | "isUserSubscriber"
                                >
                            >
                        >;
                    }
            >;
        };
};

export type SearchItemPartsFragment = { readonly __typename?: "SearchItem" } & Pick<
    SearchItem,
    "id" | "name" | "htmlName" | "htmlDescription" | "matchType" | "propertyType" | "country" | "item"
> & {
        readonly redirect?: Maybe<
            | ({ readonly __typename: "SearchFormRedirect" } & Pick<
                  SearchFormRedirect,
                  "action" | "method" | "formData"
              >)
            | ({ readonly __typename: "SearchHrefRedirect" } & Pick<SearchHrefRedirect, "href">)
        >;
    };

export type HamburgerMenuDataQueryVariables = Exact<{
    ApplicationId?: InputMaybe<Scalars["Int"]>;
    Culture?: InputMaybe<Scalars["String"]>;
    MarketingBrandId?: InputMaybe<Scalars["Int"]>;
}>;

export type HamburgerMenuDataQuery = { readonly __typename?: "Query" } & {
    readonly user: { readonly __typename?: "User" } & {
        readonly hamburgerMenu?: Maybe<
            { readonly __typename?: "HamburgerMenu" } & Pick<
                HamburgerMenu,
                | "MenuId"
                | "MarketingBrandId"
                | "ApplicationId"
                | "ViewName"
                | "IsActivated"
                | "MenuItemGroups"
                | "NotActivated"
            > & {
                    readonly MenuItems?: Maybe<
                        ReadonlyArray<
                            Maybe<
                                { readonly __typename?: "MenuItem" } & Pick<
                                    MenuItem,
                                    | "MenuItemId"
                                    | "MenuItemName"
                                    | "MenuItemTypeId"
                                    | "MenuItemTypeName"
                                    | "MenuItemUrl"
                                    | "MenuItemParentId"
                                    | "LevelId"
                                    | "DisplayOrder"
                                    | "GroupName"
                                    | "IsActivated"
                                    | "CheckUserAttribute"
                                    | "CheckEntitlement"
                                    | "MenuItemDisplayName"
                                    | "MenuItemUserAttributes"
                                    | "MenuItemGroupAttributes"
                                > & {
                                        readonly ChildItems?: Maybe<
                                            ReadonlyArray<
                                                Maybe<
                                                    { readonly __typename?: "MenuItem" } & Pick<
                                                        MenuItem,
                                                        | "MenuItemId"
                                                        | "MenuItemName"
                                                        | "MenuItemTypeId"
                                                        | "MenuItemTypeName"
                                                        | "MenuItemUrl"
                                                        | "MenuItemParentId"
                                                        | "LevelId"
                                                        | "DisplayOrder"
                                                        | "GroupName"
                                                        | "IsActivated"
                                                        | "CheckUserAttribute"
                                                        | "CheckEntitlement"
                                                        | "MenuItemDisplayName"
                                                        | "MenuItemUserAttributes"
                                                        | "MenuItemGroupAttributes"
                                                    > & {
                                                            readonly ChildItems?: Maybe<
                                                                ReadonlyArray<
                                                                    Maybe<
                                                                        { readonly __typename?: "MenuItem" } & Pick<
                                                                            MenuItem,
                                                                            | "MenuItemId"
                                                                            | "MenuItemName"
                                                                            | "MenuItemTypeId"
                                                                            | "MenuItemTypeName"
                                                                            | "MenuItemUrl"
                                                                            | "MenuItemParentId"
                                                                            | "LevelId"
                                                                            | "DisplayOrder"
                                                                            | "GroupName"
                                                                            | "IsActivated"
                                                                            | "CheckUserAttribute"
                                                                            | "CheckEntitlement"
                                                                            | "MenuItemDisplayName"
                                                                            | "MenuItemUserAttributes"
                                                                            | "MenuItemGroupAttributes"
                                                                        > & {
                                                                                readonly MenuItemDisplayNames?: Maybe<
                                                                                    ReadonlyArray<
                                                                                        Maybe<
                                                                                            {
                                                                                                readonly __typename?: "MenuItemDisplayName";
                                                                                            } & Pick<
                                                                                                MenuItemDisplayName,
                                                                                                | "MenuItemDisplayAttributeId"
                                                                                                | "MenuItemId"
                                                                                                | "Culture"
                                                                                                | "DisplayAttributeName"
                                                                                                | "DisplayAttributeValue"
                                                                                            >
                                                                                        >
                                                                                    >
                                                                                >;
                                                                                readonly MenuItemEntitlements?: Maybe<
                                                                                    ReadonlyArray<
                                                                                        Maybe<
                                                                                            {
                                                                                                readonly __typename?: "MenuItemEntitlement";
                                                                                            } & Pick<
                                                                                                MenuItemEntitlement,
                                                                                                | "MenuItemEntitlementMappingId"
                                                                                                | "MenuItemId"
                                                                                                | "EntitlementTypeId"
                                                                                                | "EntitlementTypeName"
                                                                                                | "ValueType"
                                                                                                | "Value"
                                                                                            >
                                                                                        >
                                                                                    >
                                                                                >;
                                                                                readonly MenuItemAttributes?: Maybe<
                                                                                    ReadonlyArray<
                                                                                        Maybe<
                                                                                            {
                                                                                                readonly __typename?: "MenuItemAttribute";
                                                                                            } & Pick<
                                                                                                MenuItemAttribute,
                                                                                                | "MenuItemAttributeId"
                                                                                                | "MenuItemId"
                                                                                                | "MenuItemAttributeName"
                                                                                                | "MenuItemAttributeValue"
                                                                                            >
                                                                                        >
                                                                                    >
                                                                                >;
                                                                            }
                                                                    >
                                                                >
                                                            >;
                                                            readonly MenuItemDisplayNames?: Maybe<
                                                                ReadonlyArray<
                                                                    Maybe<
                                                                        {
                                                                            readonly __typename?: "MenuItemDisplayName";
                                                                        } & Pick<
                                                                            MenuItemDisplayName,
                                                                            | "MenuItemDisplayAttributeId"
                                                                            | "MenuItemId"
                                                                            | "Culture"
                                                                            | "DisplayAttributeName"
                                                                            | "DisplayAttributeValue"
                                                                        >
                                                                    >
                                                                >
                                                            >;
                                                            readonly MenuItemEntitlements?: Maybe<
                                                                ReadonlyArray<
                                                                    Maybe<
                                                                        {
                                                                            readonly __typename?: "MenuItemEntitlement";
                                                                        } & Pick<
                                                                            MenuItemEntitlement,
                                                                            | "MenuItemEntitlementMappingId"
                                                                            | "MenuItemId"
                                                                            | "EntitlementTypeId"
                                                                            | "EntitlementTypeName"
                                                                            | "ValueType"
                                                                            | "Value"
                                                                        >
                                                                    >
                                                                >
                                                            >;
                                                            readonly MenuItemAttributes?: Maybe<
                                                                ReadonlyArray<
                                                                    Maybe<
                                                                        {
                                                                            readonly __typename?: "MenuItemAttribute";
                                                                        } & Pick<
                                                                            MenuItemAttribute,
                                                                            | "MenuItemAttributeId"
                                                                            | "MenuItemId"
                                                                            | "MenuItemAttributeName"
                                                                            | "MenuItemAttributeValue"
                                                                        >
                                                                    >
                                                                >
                                                            >;
                                                        }
                                                >
                                            >
                                        >;
                                        readonly MenuItemDisplayNames?: Maybe<
                                            ReadonlyArray<
                                                Maybe<
                                                    { readonly __typename?: "MenuItemDisplayName" } & Pick<
                                                        MenuItemDisplayName,
                                                        | "MenuItemDisplayAttributeId"
                                                        | "MenuItemId"
                                                        | "Culture"
                                                        | "DisplayAttributeName"
                                                        | "DisplayAttributeValue"
                                                    >
                                                >
                                            >
                                        >;
                                        readonly MenuItemEntitlements?: Maybe<
                                            ReadonlyArray<
                                                Maybe<
                                                    { readonly __typename?: "MenuItemEntitlement" } & Pick<
                                                        MenuItemEntitlement,
                                                        | "MenuItemEntitlementMappingId"
                                                        | "MenuItemId"
                                                        | "EntitlementTypeId"
                                                        | "EntitlementTypeName"
                                                        | "ValueType"
                                                        | "Value"
                                                    >
                                                >
                                            >
                                        >;
                                        readonly MenuItemAttributes?: Maybe<
                                            ReadonlyArray<
                                                Maybe<
                                                    { readonly __typename?: "MenuItemAttribute" } & Pick<
                                                        MenuItemAttribute,
                                                        | "MenuItemAttributeId"
                                                        | "MenuItemId"
                                                        | "MenuItemAttributeName"
                                                        | "MenuItemAttributeValue"
                                                    >
                                                >
                                            >
                                        >;
                                    }
                            >
                        >
                    >;
                    readonly MenuAttributes?: Maybe<
                        ReadonlyArray<
                            Maybe<
                                { readonly __typename?: "MenuAttribute" } & Pick<
                                    MenuAttribute,
                                    | "MenuAttributeId"
                                    | "MenuId"
                                    | "MenuAttributeName"
                                    | "MenuAttributeValueType"
                                    | "MenuAttributeValue"
                                >
                            >
                        >
                    >;
                    readonly MenuAttributesDisplay?: Maybe<
                        ReadonlyArray<
                            Maybe<
                                { readonly __typename?: "MenuAttributeDisplay" } & Pick<
                                    MenuAttributeDisplay,
                                    | "MenuAttributeDisplayId"
                                    | "MenuId"
                                    | "Culture"
                                    | "DisplayAttributeName"
                                    | "DisplayAttributeValue"
                                >
                            >
                        >
                    >;
                }
        >;
    };
};

export type HeaderQueryVariables = Exact<{
    activeProductKey?: InputMaybe<Scalars["String"]>;
    culture?: InputMaybe<Scalars["String"]>;
}>;

export type HeaderQuery = { readonly __typename?: "Query" } & {
    readonly user: { readonly __typename?: "User" } & {
        readonly apps: ReadonlyArray<
            { readonly __typename?: "UserApp" } & Pick<
                UserApp,
                | "id"
                | "i18nKey"
                | "text"
                | "defaultHref"
                | "href"
                | "isUserSubscriber"
                | "alternatePaths"
                | "countriesSubscriptions"
                | "ignorePaths"
                | "isActive"
            > & {
                    readonly sections: ReadonlyArray<
                        { readonly __typename?: "UserAppSection" } & Pick<
                            UserAppSection,
                            | "id"
                            | "i18nKey"
                            | "text"
                            | "landingPath"
                            | "baseUrl"
                            | "href"
                            | "alternatePaths"
                            | "ignorePaths"
                            | "isUserSubscriber"
                        > & {
                                readonly dropdownItems?: Maybe<
                                    ReadonlyArray<
                                        { readonly __typename?: "UserAppSectionDropdownItem" } & Pick<
                                            UserAppSectionDropdownItem,
                                            | "id"
                                            | "isHeader"
                                            | "i18nKey"
                                            | "text"
                                            | "landingPath"
                                            | "baseUrl"
                                            | "alternatePaths"
                                            | "ignorePaths"
                                            | "href"
                                            | "isUserSubscriber"
                                        >
                                    >
                                >;
                            }
                    >;
                    readonly hoverItems?: Maybe<
                        ReadonlyArray<
                            { readonly __typename?: "UserAppSection" } & Pick<
                                UserAppSection,
                                "id" | "i18nKey" | "text" | "landingPath" | "baseUrl" | "href" | "isUserSubscriber"
                            >
                        >
                    >;
                }
        >;
    };
    readonly getUserInfo?: Maybe<
        { readonly __typename?: "UserInfo" } & Pick<UserInfo, "contactId" | "locationCountryCode">
    >;
};

export type HealthCheckQueryVariables = Exact<{ [key: string]: never }>;

export type HealthCheckQuery = { readonly __typename?: "Query" } & {
    readonly search: { readonly __typename?: "SearchQuery" } & Pick<SearchQuery, "healthCheck">;
};

export type OmniSearchQueryVariables = Exact<{
    term?: InputMaybe<Scalars["String"]>;
    searchType?: InputMaybe<SearchType>;
    countryCode?: InputMaybe<Scalars["String"]>;
    secondaryLookup?: InputMaybe<Scalars["Boolean"]>;
    preqinEnabled?: InputMaybe<Scalars["Boolean"]>;
}>;

export type OmniSearchQuery = { readonly __typename?: "Query" } & {
    readonly search: { readonly __typename?: "SearchQuery" } & {
        readonly all: { readonly __typename?: "SearchResult" } & {
            readonly entries: ReadonlyArray<
                { readonly __typename?: "SearchItem" } & Pick<
                    SearchItem,
                    "id" | "name" | "htmlName" | "htmlDescription" | "matchType" | "propertyType" | "country" | "item"
                > & {
                        readonly redirect?: Maybe<
                            | ({ readonly __typename: "SearchFormRedirect" } & Pick<
                                  SearchFormRedirect,
                                  "action" | "method" | "formData"
                              >)
                            | ({ readonly __typename: "SearchHrefRedirect" } & Pick<SearchHrefRedirect, "href">)
                        >;
                    }
            >;
        };
    };
};

export type RecentlyViewedQueryVariables = Exact<{
    culture?: InputMaybe<Scalars["String"]>;
}>;

export type RecentlyViewedQuery = { readonly __typename?: "Query" } & {
    readonly search: { readonly __typename?: "SearchQuery" } & {
        readonly recentlyViewed: { readonly __typename?: "RecentlyViewed" } & {
            readonly userEntities?: Maybe<
                ReadonlyArray<
                    Maybe<
                        { readonly __typename?: "UserEntities" } & Pick<
                            UserEntities,
                            | "UserEntityId"
                            | "verticalTag"
                            | "entityTag"
                            | "groupTag"
                            | "instance"
                            | "productType"
                            | "status"
                            | "updatedTime"
                            | "isDefault"
                        > & {
                                readonly value: { readonly __typename?: "RecentlyViewedValues" } & {
                                    readonly list?: Maybe<
                                        ReadonlyArray<
                                            { readonly __typename?: "RecentlyViewedValuesList" } & Pick<
                                                RecentlyViewedValuesList,
                                                "address" | "id" | "idType" | "lvt"
                                            > & {
                                                    readonly redirect?: Maybe<
                                                        { readonly __typename?: "SearchHrefRedirect" } & Pick<
                                                            SearchHrefRedirect,
                                                            "href"
                                                        >
                                                    >;
                                                }
                                        >
                                    >;
                                };
                            }
                    >
                >
            >;
        };
    };
};

export type SearchCriteriaQueryVariables = Exact<{
    item: Scalars["String"];
    searchType: SearchType;
    productType: Scalars["Int"];
}>;

export type SearchCriteriaQuery = { readonly __typename?: "Query" } & {
    readonly search: { readonly __typename?: "SearchQuery" } & Pick<SearchQuery, "criteria">;
};

export type UserInfoQueryVariables = Exact<{ [key: string]: never }>;

export type UserInfoQuery = { readonly __typename?: "Query" } & {
    readonly user: { readonly __typename?: "User" } & {
        readonly info?: Maybe<
            { readonly __typename?: "UserInfo" } & Pick<
                UserInfo,
                "id" | "name" | "worksForCoStar" | "email" | "companyId" | "rootCompanyId" | "companyName" | "contactId"
            > & {
                    readonly preferences: { readonly __typename?: "UserPreferenceResult" } & {
                        readonly entries?: Maybe<
                            ReadonlyArray<
                                { readonly __typename?: "UserPreference" } & Pick<
                                    UserPreference,
                                    "identifier" | "value" | "isDefault"
                                >
                            >
                        >;
                    };
                }
        >;
    };
};

export const SearchItemParts = gql`
    fragment SearchItemParts on SearchItem {
        id
        name
        htmlName
        htmlDescription
        matchType
        propertyType
        country
        item
        redirect {
            __typename
            ... on SearchHrefRedirect {
                href
            }
            ... on SearchFormRedirect {
                action
                method
                formData
            }
        }
    }
`;
export const UniversalUiContextDocument = gql`
    query UniversalUIContext($activeProductKey: String) {
        user {
            isAuth
            culture
            uuiEnabled
            info {
                id
                name
                worksForCoStar
                email
                companyId
                rootCompanyId
                companyName
                contactId
                preferences {
                    entries {
                        identifier
                        value
                        isDefault
                    }
                }
            }
            headers {
                professionalsEnabled
                leaseCompsEnabled
                tenantEnabled
            }
            appFeatures {
                id
                name
                enabled
            }
            apps(input: { activeProductKey: $activeProductKey }) {
                id
                i18nKey
                href
                isUserSubscriber
                alternatePaths
                countriesSubscriptions
                ignorePaths
                isActive
                sections {
                    id
                    i18nKey
                    landingPath
                    baseUrl
                    href
                    alternatePaths
                    ignorePaths
                    isUserSubscriber
                    dropdownItems {
                        id
                        isHeader
                        i18nKey
                        landingPath
                        baseUrl
                        alternatePaths
                        ignorePaths
                        href
                        isUserSubscriber
                    }
                }
                hoverItems {
                    id
                    i18nKey
                    landingPath
                    baseUrl
                    href
                    isUserSubscriber
                }
            }
        }
    }
`;
export const HamburgerMenuDataDocument = gql`
    query HamburgerMenuData($ApplicationId: Int, $Culture: String, $MarketingBrandId: Int) {
        user {
            hamburgerMenu(ApplicationId: $ApplicationId, Culture: $Culture, MarketingBrandId: $MarketingBrandId) {
                MenuId
                MarketingBrandId
                ApplicationId
                ViewName
                IsActivated
                MenuItems {
                    MenuItemId
                    MenuItemName
                    MenuItemTypeId
                    MenuItemTypeName
                    MenuItemUrl
                    MenuItemParentId
                    LevelId
                    DisplayOrder
                    GroupName
                    IsActivated
                    CheckUserAttribute
                    CheckEntitlement
                    ChildItems {
                        MenuItemId
                        MenuItemName
                        MenuItemTypeId
                        MenuItemTypeName
                        MenuItemUrl
                        MenuItemParentId
                        LevelId
                        DisplayOrder
                        GroupName
                        IsActivated
                        CheckUserAttribute
                        CheckEntitlement
                        ChildItems {
                            MenuItemId
                            MenuItemName
                            MenuItemTypeId
                            MenuItemTypeName
                            MenuItemUrl
                            MenuItemParentId
                            LevelId
                            DisplayOrder
                            GroupName
                            IsActivated
                            CheckUserAttribute
                            CheckEntitlement
                            MenuItemDisplayName
                            MenuItemDisplayNames {
                                MenuItemDisplayAttributeId
                                MenuItemId
                                Culture
                                DisplayAttributeName
                                DisplayAttributeValue
                            }
                            MenuItemEntitlements {
                                MenuItemEntitlementMappingId
                                MenuItemId
                                EntitlementTypeId
                                EntitlementTypeName
                                ValueType
                                Value
                            }
                            MenuItemUserAttributes
                            MenuItemAttributes {
                                MenuItemAttributeId
                                MenuItemId
                                MenuItemAttributeName
                                MenuItemAttributeValue
                            }
                            MenuItemGroupAttributes
                        }
                        MenuItemDisplayName
                        MenuItemDisplayNames {
                            MenuItemDisplayAttributeId
                            MenuItemId
                            Culture
                            DisplayAttributeName
                            DisplayAttributeValue
                        }
                        MenuItemEntitlements {
                            MenuItemEntitlementMappingId
                            MenuItemId
                            EntitlementTypeId
                            EntitlementTypeName
                            ValueType
                            Value
                        }
                        MenuItemUserAttributes
                        MenuItemAttributes {
                            MenuItemAttributeId
                            MenuItemId
                            MenuItemAttributeName
                            MenuItemAttributeValue
                        }
                        MenuItemGroupAttributes
                    }
                    MenuItemDisplayName
                    MenuItemDisplayNames {
                        MenuItemDisplayAttributeId
                        MenuItemId
                        Culture
                        DisplayAttributeName
                        DisplayAttributeValue
                    }
                    MenuItemEntitlements {
                        MenuItemEntitlementMappingId
                        MenuItemId
                        EntitlementTypeId
                        EntitlementTypeName
                        ValueType
                        Value
                    }
                    MenuItemUserAttributes
                    MenuItemAttributes {
                        MenuItemAttributeId
                        MenuItemId
                        MenuItemAttributeName
                        MenuItemAttributeValue
                    }
                    MenuItemGroupAttributes
                }
                MenuAttributes {
                    MenuAttributeId
                    MenuId
                    MenuAttributeName
                    MenuAttributeValueType
                    MenuAttributeValue
                }
                MenuAttributesDisplay {
                    MenuAttributeDisplayId
                    MenuId
                    Culture
                    DisplayAttributeName
                    DisplayAttributeValue
                }
                MenuItemGroups
                NotActivated
            }
        }
    }
`;
export const HeaderDocument = gql`
    query Header($activeProductKey: String, $culture: String) {
        user {
            apps(input: { activeProductKey: $activeProductKey, culture: $culture }) {
                id
                i18nKey
                text
                defaultHref
                href
                isUserSubscriber
                alternatePaths
                countriesSubscriptions
                ignorePaths
                isActive
                sections {
                    id
                    i18nKey
                    text
                    landingPath
                    baseUrl
                    href
                    alternatePaths
                    ignorePaths
                    isUserSubscriber
                    dropdownItems {
                        id
                        isHeader
                        i18nKey
                        text
                        landingPath
                        baseUrl
                        alternatePaths
                        ignorePaths
                        href
                        isUserSubscriber
                    }
                }
                hoverItems {
                    id
                    i18nKey
                    text
                    landingPath
                    baseUrl
                    href
                    isUserSubscriber
                }
            }
        }
        getUserInfo {
            contactId
            locationCountryCode
        }
    }
`;
export const HealthCheckDocument = gql`
    query HealthCheck {
        search {
            healthCheck
        }
    }
`;
export const OmniSearchDocument = gql`
    query OmniSearch(
        $term: String
        $searchType: SearchType = ALL_PROPERTIES
        $countryCode: String
        $secondaryLookup: Boolean
        $preqinEnabled: Boolean
    ) {
        search {
            all(
                input: { term: $term, type: $searchType, countryCode: $countryCode, secondaryLookup: $secondaryLookup }
                features: { preqinEnabled: $preqinEnabled }
            ) {
                entries {
                    ...SearchItemParts
                }
            }
        }
    }
    ${SearchItemParts}
`;
export const RecentlyViewedDocument = gql`
    query RecentlyViewed($culture: String) {
        search {
            recentlyViewed(culture: $culture) {
                userEntities {
                    UserEntityId
                    verticalTag
                    entityTag
                    groupTag
                    instance
                    productType
                    value {
                        list {
                            address
                            id
                            idType
                            lvt
                            redirect {
                                href
                            }
                        }
                    }
                    status
                    updatedTime
                    isDefault
                }
            }
        }
    }
`;
export const SearchCriteriaDocument = gql`
    query SearchCriteria($item: String!, $searchType: SearchType!, $productType: Int!) {
        search {
            criteria(item: $item, searchType: $searchType, productType: $productType)
        }
    }
`;
export const UserInfoDocument = gql`
    query UserInfo {
        user {
            info {
                id
                name
                worksForCoStar
                email
                companyId
                rootCompanyId
                companyName
                contactId
                preferences {
                    entries {
                        identifier
                        value
                        isDefault
                    }
                }
            }
        }
    }
`;

export interface PossibleTypesResultData {
    possibleTypes: {
        [key: string]: string[];
    };
}
const result: PossibleTypesResultData = {
    possibleTypes: {
        SearchRedirect: ["SearchFormRedirect", "SearchHrefRedirect"],
    },
};
export default result;
