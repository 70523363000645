import { Resources } from "../../../../i18n/resources";
import { HOME_URL, UnauthenticatedClientConfig } from "../../../common";
import { AcceptedLanguage } from "../../../types";

import { RightNavContentProps } from "./types";

export default function buildRightNavOptions(
    culturalResources: Resources,
    configuration?: UnauthenticatedClientConfig
): RightNavContentProps {
    const supportedLocales = ["en-US", "en-GB", "en-CA"] as AcceptedLanguage[];

    const enableFrench = configuration?.appFeatures?.newsenablefranceoption;

    //in the case where the cs-features-NewsEnableFranceOption header is true
    if (typeof enableFrench === "string" && enableFrench === "true") {
        supportedLocales.push("fr-FR" as AcceptedLanguage);
    }

    // //in the case where the cs-features-NewsEnableFranceOption header does not exist, use the default config value
    else if (typeof enableFrench === "undefined") {
        if (configuration?.options?.newsHomepage?.enableFrenchLocale) {
            supportedLocales.push("fr-FR" as AcceptedLanguage);
        }
    }

    return {
        loginUrl: configuration?.options?.navLinks?.login ?? HOME_URL,
        loginLabel: culturalResources.menu.login(),
        initialLanguage: culturalResources.cultureCode() as AcceptedLanguage,
        availableLanguageOptions: {
            "en-US": culturalResources.text.culture.en_US(),
            "en-CA": culturalResources.text.culture.en_CA(),
            "en-GB": culturalResources.text.culture.en_GB(),
        },
        supportedLocales: supportedLocales,
    };
}
